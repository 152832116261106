<!DOCTYPE html>
<html>
<head>
	
	<meta charset="utf-8">
	<meta name="viewreport" content="width=device-width, initial-scale=1.0">
	<title>
		HOME
	</title>
	<meta charset="UTF-8">
	
	
	<link rel="stylesheet" href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css">
	<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" />


	<link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
	<link rel="stylesheet" type="text/css" href="https://www.w3schools.com/w3css/4/w3.css">
	<link rel="stylesheet" type="text/css" href="assessor-dashboard.component.css" />
  	<link rel="stylesheet" type="text/html" href="/resources/demos/style.css"/>

  
  <script src="https://code.jquery.com/jquery-1.10.2.js"></script>
  <script src="https://code.jquery.com/ui/1.10.4/jquery-ui.js"></script>
  <link href="https://code.jquery.com/ui/1.10.4/themes/ui-lightness/jquery-ui.css">
</head>

<body>
	<script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"></script>
	<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
	<script src="https://cdn.datatables.net/1.10.19/js/jquery.dataTables.min.js"></script>
	<script src="https://cdn.datatables.net/1.10.19/js/dataTables.bootstrap4.min.js"></script>

	<app-header></app-header>
	<div class="col-md-12">
							
			<app-navbar></app-navbar>
		
	</div>
	<div class="borderline"></div>
	
		
	<div class="container-fluid banner">
		<div class="spinner-border text-primary" id="image" style="display: none"></div>


        <div class="title">
			<h4>ASSESSOR AVAILABILITY</h4>
			<ol>
			  <li class="breadcrumb-item active">Dashboard</li>
			</ol>
		  
		</div>
      <div class="container">
        <table class="mdl-data-table stripe hover cell-border order-column display" id="myTable">
        <thead>
            <tr>
              <th>S.No.</th>
              <th>Sector</th>
              <th>QP Code</th>
              <th>QP Name</th>
              <th>Allocation</th>
              <th>Assessed</th>
              <th>Absent</th>
              <th>Dropout</th>
            </tr>
		  </thead>
		</table>
	  </div>
		
	</div>

	<div class="footer">
		<app-footer></app-footer>
	</div>
	</body>

</html>
