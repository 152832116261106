<html>
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </head>
  <body>
    <section>
      <app-header></app-header>
      <app-navbar></app-navbar>
      <!--div class="nav">
        <div class="logo">
          <img src="/assets/images/logo.png" />
        </div>
        <button
          class="btn dropdown-toggle mr-4 shadow-lg"
          type="button"
          id="drop"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ UserId }}
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/changepassword']"
            >Change Password</a
          >
          <a class="dropdown-item" [routerLink]="['/login']">Update Profile</a>
          <a class="dropdown-item" (click)="back_button_clicked()">Back</a>
          <a class="dropdown-item" [routerLink]="['/login']">Log Out</a>
        </div>
        <div class="burger">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </div>
      </div-->
      <div class="borderline"></div>
      <div class="heading">
        <h1>Proctor Attributes</h1>
      </div>

      <div
        class="spinner-border text-primary"
        id="image"
        style="display: none"
      ></div>
      <div class="container">
        <table
          class="mdl-data-table stripe hover cell-border order-column display"
          id="myTable"
        >
          <thead>
            <tr>
              <th>Facilitator Id</th>
              <th>Facilitator Name</th>
              <th>Facilitator Email</th>
              <th>Facilitator Phone</th>
              <th>Facilitator Alternate Phone</th>
              <th>Date Of Upload</th>
              <th>District</th>
              <th>State</th>
              <th>Aadhaar Number</th>
              <th>Pan Number</th>
              <th>Proctor Status</th>
              <th>Certificate File Name</th>
              <th>Certificate Expiry Date</th>
              <th>Languages Known</th>
              <th>Source</th>
              <th>Sourced By User Name</th>
              <th>Bank Account Number</th>
              <th>Bank Name</th>
              <th>IFSC Code</th>
              <th>Cancelled Cheque File Name</th>
              <th>MoU File Name</th>
              <th>Facilitator Image File Name</th>
              <th>Facilitator Resume File Name</th>
              <th>Education Certificate File Name</th>
              <th>Experience Certificate File Name</th>
              <th>Status</th>
            </tr>
          </thead>
        </table>
      </div>
      <!--div
        class="modal-footer mx-5 pt-2 mb-1 justify-content-center fixed-bottom"
        style="font-size: 16px"
      >
        <p style="color: rgb(255, 255, 255)" class="d-flex">
          <b>© 2020 Copyright :</b>
          <a
            style="color: rgb(240, 234, 234); padding: 0px 6px"
            href="https://navriti.com/"
            class="ml-1"
            target="_blank"
            ><b> Navriti Technologies Pvt Ltd. </b></a
          >
        </p>
      </div-->
      <app-footers></app-footers>
    </section>

    <script src="man.js" async defer></script>
  </body>
</html>
