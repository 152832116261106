
<!DOCTYPE html>
<html>
<head>
	
	<meta charset="utf-8">
	<meta name="viewreport" content="width=device-width, initial-scale=1.0">
	<title>
		HOME
	</title>
	<meta charset="UTF-8">

	<link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
	<link rel="stylesheet" type="text/css" href="assessment-evaluation.component.css" />
  	<link rel="stylesheet" type="text/html" href="/resources/demos/style.css"/>

</head>

<body id="body">

	<app-header></app-header>
	<div class="col-md-12">				
			<app-navbar></app-navbar>
	</div>
  <div class="borderline"></div>
  
  <div class="title">
	<h4>ASSESSMENT EVALUATION</h4>
	<div style="display:inline-block; margin-left:33%"  *ngIf="reqType=='PAST'; else elseBlock1" >
    <nav aria-label="breadcrumb">
      <ol style="background: transparent;" class="breadcrumb">
		<li *ngIf="UserRole=='3'; else elseBlock" class="breadcrumb-item">
			<a style="color: rgb(32, 32, 238);" [routerLink]="['/AssessorDetails']">Dashboard</a>
		</li>
		<ng-template #elseBlock>
			<a style="color: rgb(32, 32, 238);" [routerLink]="['/home']">Dashboard</a>
		</ng-template>
        <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/past_batches']" [queryParams]="{RequestType : 'PAST'}">Past Batches</a></li>
        <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/AssessmentEvaluationCandidateList']" >Candidate List</a></li>
        <li class="breadcrumb-item active">Assessment Evaluation</li>
      </ol>
	</nav>
	</div>

	<div class="loader" id='image' style='display: none;'></div>

	<ng-template #elseBlock1>
		<div style="display:inline-block; margin-left:33%"  *ngIf="reqType=='TODAY'; else elseBlock2" >
		<nav aria-label="breadcrumb">
			<ol style="background: transparent;" class="breadcrumb">
				<li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/home']" >Dashboard</a></li>

			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/todays_batches']" [queryParams]="{RequestType : 'TODAY'}">Todays Batches</a></li>
			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/AssessmentEvaluationCandidateList']" >Candidate List</a></li>
              <li class="breadcrumb-item active">Assessment Evaluation</li>
			</ol>
		  </nav>
		</div>
	</ng-template>

	<ng-template #elseBlock2>
		<nav aria-label="breadcrumb" style="display:inline-block; margin-left:33%" >
			<ol style="background: transparent;" class="breadcrumb">
				<li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/home']" >Dashboard</a></li>

			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/upcoming_batches']" [queryParams]="{RequestType : 'UPCOMING'}">Upcoming Batches</a></li>
			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/AssessmentEvaluationCandidateList']" >Candidate List</a></li>
              <li class="breadcrumb-item active">Assessment Evaluation</li>
			</ol>
		  </nav>
	</ng-template>
  </div>
			
	<div class="container-fluid banner">
		
			  <table class="table-sortable table-striped" id="tblAssessmentCandidates" border="1">
				<div class="spinner-border text-primary" id="image" style="display: none"></div>
			  <thead>
				  <tr>
					<th  [attr.rowspan]="2">S.No</th>
					<th  [attr.rowspan]="2" style="width: 15%;">Section</th>
					<th  [attr.rowspan]="2">Question</th>
                    <th  [attr.rowspan]="2">Video Response</th>
          
                    <th [attr.rowspan]="2" style="width: 35%;">Performance Criteria</th>
					<th colspan="2">Observation</th>
					<th colspan="2">Viva</th>
                </tr>
                <tr>
                    <th>Alloted Marks</th>
                    <th>Obtained Marks</th>

                    <th>Obtained Marks</th>
                    <th>Obtained Marks</th>
                </tr>
				</thead>
				<tbody id="tblBody">
					<tr>
						<td colspan="9">Loading data...</td>
					</tr>
				</tbody>
					
			  </table>
			  <div class="row-fluid">
				<div class="span12">
					<label id="lblSubmitStatus">Could not submit the assessment evaluation!</label>
					<div style="clear:both;"></div>
					<button id="btnSubmit" (click)="SubmitEvaluation()" class="btn btn-success">Submit Evaluation</button>
				</div>
			</div>
			</div>
	</body>

</html>