import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-candidate-info",
  templateUrl: "./candidate-info.component.html",
})
export class CandidateInfoComponent implements OnInit {
  candidateDate: any;
  minutes: any;
  seconds: any;
  constructor(
    public dialogRef: MatDialogRef<CandidateInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.candidateDate = data.data.CandidateAssessmentData;
  }

  ngOnInit(): void {
    this.minutes = Math.floor(
      this.candidateDate.QuestionPaperDurationSeconds / 60
    );
    this.seconds =
      this.candidateDate.QuestionPaperDurationSeconds - this.minutes * 60;
  }
}
