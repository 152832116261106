import { environment } from "./../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { element } from "protractor";
import { NgZone } from "@angular/core";
import {
  Component,
  OnInit,
  ɵConsole,
  ComponentFactoryResolver,
} from "@angular/core";
import * as $ from "jquery";
declare const DetectRTC: any;
declare const alertify: any;
var WebCam: any;
@Component({
  selector: "app-assessment-detail",
  templateUrl: "./assessment-detail.component.html",
  styleUrls: ["./assessment-details.component.css"],
})
export class AssessmentDetailComponent {
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private ngZone: NgZone
  ) {}
  Req: string;
  Id: string;
  ngOnInit(): void {
    this.Req = localStorage.getItem("req_id");
    this.Id = localStorage.getItem("cand_id");
    this.ajaxcall();
  }

  ajaxcall() {
    var data: any = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_" + "data")
    );
    WebCam = data.CandidateAssessmentData.WebCamMandatory;
    // if (WebCam) WebCam = true;
    // else WebCam = false;
    console.log(WebCam);
    $(document).ready(function () {
      var lang = "";
      for (
        var i = 0;
        i < parseInt(data.CandidateAssessmentData.Languages.length);
        i++
      ) {
        if (i != parseInt(data.CandidateAssessmentData.Languages.length) - 1)
          lang += data.CandidateAssessmentData.Languages[i].LanguageName + ", ";
        else lang += data.CandidateAssessmentData.Languages[i].LanguageName;
      }
      document.getElementById("tablecontent").innerHTML =
        "<br />" +
        '<b style="padding:10px"> Candidate Name : </b>' +
        data.CandidateAssessmentData.CandidateName +
        '<b style="padding:10px"> Date of Birth : </b>' +
        "24-Aug-1999" +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> Registration Number : </b>' +
        data.CandidateAssessmentData.RegistrationId +
        '<b style="padding:10px"> Guardian Name : </b>' +
        data.CandidateAssessmentData.GuardianName +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> Assessment Batch :</b>' +
        data.CandidateAssessmentData.AssessmentBatchCode.toUpperCase() +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> SDMS Batch ID : </b>' +
        data.CandidateAssessmentData.SDMSBatchId.toUpperCase() +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> Project Name : </b>' +
        data.CandidateAssessmentData.ProjectName +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> Client Name : </b>' +
        data.CandidateAssessmentData.ClientName +
        "<hr style='height:1px;border-width:0;color:black;background-color:black'>" +
        '<b style="padding:10px"> Languages : </b>' +
        lang +
        "<br />" +
        "<br/>";
      if (data.CandidateAssessmentData.TheoryAssessment) {
        document.getElementById("vid1").style.visibility = "visible";
        document.getElementById("btn1").style.visibility = "visible";
      }
      if (data.CandidateAssessmentData.PracticalAssessment) {
        document.getElementById("vid2").style.visibility = "visible";
        document.getElementById("btn2").style.visibility = "visible";
      }
      if (data.CandidateAssessmentData.VivaMcqAssessment) {
        document.getElementById("vid3").style.visibility = "visible";
        document.getElementById("btn3").style.visibility = "visible";
      }
      if (data.CandidateAssessmentData.TheoryAssessment) {
        if (
          parseInt(
            data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId
          ) == environment.ASSESSMENT_SUBMITTED
        ) {
          document.getElementById("btn1").className = "btn btn-success";
          document.getElementById("btn1").setAttribute("disabled", "disabled");
        }
      }
      if (data.CandidateAssessmentData.PracticalAssessment) {
        if (
          parseInt(
            data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId
          ) == environment.ASSESSMENT_SUBMITTED
        ) {
          document.getElementById("btn2").className = "btn btn-success";
          document.getElementById("btn2").setAttribute("disabled", "disabled");
        }
      }
      if (data.CandidateAssessmentData.VivaMcqAssessment) {
        if (
          parseInt(
            data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId
          ) == environment.ASSESSMENT_SUBMITTED
        ) {
          document.getElementById("btn3").className = "btn btn-success";
          document.getElementById("btn3").setAttribute("disabled", "disabled");
        }
      }
    });
  }

  clicked_theory() {
    localStorage.setItem(this.Req + "_" + this.Id + "_assessment", "theory");
    localStorage.setItem("assessment", "theory");

    var theoryData = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_" + "data")
    );
    const that = this;

    var TheoryWebCam =
      theoryData.CandidateAssessmentData.TheoryAssessment.WebcamMandatory;

    if (TheoryWebCam == true) {
      that.ngZone.run(() => that.route.navigate(["image-capture"]));
    } else {
      that.ngZone.run(() => that.route.navigate(["theory-instructions"]));
      sessionStorage.setItem("NoWebCamFound", "true");
    }

    // else {
    //   navigator.getUserMedia(
    //     { video: true },
    //     function () {
    //       that.ngZone.run(() => that.route.navigate(["theory-instructions"]));
    //       sessionStorage.setItem("NoWebCamFound", "true");
    //      // that.ngZone.run(() => that.route.navigate(["image-capture"]));
    //     },
    //     function () {
    //       that.ngZone.run(() => that.route.navigate(["theory-instructions"]));
    //       sessionStorage.setItem("NoWebCamFound", "true");
    //     }
    //   );
    // }
  }
  clicked_practical() {
    localStorage.setItem(this.Req + "_" + this.Id + "_assessment", "practical");
    localStorage.setItem("assessment", "practical");
    const that = this;
    // this.data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId = 3;

    var practicleData = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_" + "data")
    );
    var practicelWebCam =
      practicleData.CandidateAssessmentData.PracticalAssessment.WebcamMandatory;

    if (practicelWebCam == true) {
      that.ngZone.run(() => that.route.navigate(["image-capture"]));
    } else {
      alertify
        .alert(
          "Webcam is not found! Please connect to a webcam to proceed.",
          function (e: any) {
            that.ngZone.run(() => that.route.navigate(["login"]));
          }
        )
        .set("closable", false)
        .setHeader(
          `<div style="width: 111%;background-color:#66B265;color:white;margin-left: -24px;
            margin-top: -16px;
            height: 39px;
            padding: 5px 2px 2px 15px;"><h3><b> Error! </b></h3></div>`
        )
        .set("notifier", "position", "top-center");
    }
  }
  clicked_viva() {
    localStorage.setItem(this.Req + "_" + this.Id + "_assessment", "viva");
    localStorage.setItem("assessment", "viva");
    const that = this;

    var vivaData = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_" + "data")
    );
    var vivaWebCam =
      vivaData.CandidateAssessmentData.VivaMcqAssessment.WebcamMandatory;

    if (vivaWebCam == true) {
      that.ngZone.run(() => that.route.navigate(["image-capture"]));
    } else {
      that.ngZone.run(() => that.route.navigate(["viva-instructions"]));
      sessionStorage.setItem("NoWebCamFound", "true");
    }

    // else {
    //   navigator.getUserMedia(
    //     { video: true },
    //     function () {
    //      // that.ngZone.run(() => that.route.navigate(["image-capture"]));
    //      that.ngZone.run(() => that.route.navigate(["viva-instructions"]));
    //       sessionStorage.setItem("NoWebCamFound", "true");
    //     },
    //     function () {
    //       that.ngZone.run(() => that.route.navigate(["viva-instructions"]));
    //       sessionStorage.setItem("NoWebCamFound", "true");
    //     }
    //   );
    // }
  }
  vid_theory() {
    $("#example1").attr("src", environment.Theory_TutorialVideo_URL);
    document.getElementById("example1").style.display = "block";
    document.getElementById("example2").style.display = "none";
    document.getElementById("example3").style.display = "none";
    var lightBoxVideo = <HTMLVideoElement>document.getElementById("example1");
    window.scrollTo(0, 0);
    document.getElementById("light").style.display = "block";
    document.getElementById("fade").style.display = "block";
    lightBoxVideo.play();
  }
  vid_practical() {
    $("#example2").attr("src", environment.Practical_TutorialVideo_URL);
    document.getElementById("example2").style.display = "block";
    document.getElementById("example1").style.display = "none";
    document.getElementById("example3").style.display = "none";
    var lightBoxVideo = <HTMLVideoElement>document.getElementById("example2");
    window.scrollTo(0, 0);
    document.getElementById("light").style.display = "block";
    document.getElementById("fade").style.display = "block";
    lightBoxVideo.play();
  }

  vid_viva() {
    $("#example3").attr("src", environment.Viva_TutorialVideo_URL);
    document.getElementById("example3").style.display = "block";
    document.getElementById("example2").style.display = "none";
    document.getElementById("example1").style.display = "none";
    var lightBoxVideo = <HTMLVideoElement>document.getElementById("example3");
    window.scrollTo(0, 0);
    document.getElementById("light").style.display = "block";
    document.getElementById("fade").style.display = "block";
    lightBoxVideo.play();
  }
  close() {
    var lightBoxVideo1 = <HTMLVideoElement>document.getElementById("example1");
    var lightBoxVideo2 = <HTMLVideoElement>document.getElementById("example2");
    var lightBoxVideo3 = <HTMLVideoElement>document.getElementById("example3");
    document.getElementById("light").style.display = "none";
    document.getElementById("fade").style.display = "none";
    lightBoxVideo1.pause();
    lightBoxVideo2.pause();
    lightBoxVideo3.pause();
  }
}
