<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <title>LOGIN Page</title>
  <link rel="icon" href="/assets/images/Certiplate_Icon.svg" type="image/x-icon" />

  <!-- <link rel="icon" href="img/mdb-favicon.ico" type="image/x-icon" /> -->
  <!-- Font Awesome -->
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.11.2/css/all.css" />
  <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
  <!-- Google Fonts Roboto -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
  <!-- Font Awesome -->

  <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" rel="stylesheet" />
  <!-- Material Design Bootstrap -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css" rel="stylesheet" />
</head>

<body>
  <div class="bg">
    <div class="row">
      <div class="col-md-5   mx-auto my-5 col-lg-5">
        <section class="form-elegant w3-animate-zoom">
          <mdb-card style="
                background: linear-gradient(
                  rgba(0, 0, 0, 0.8),
                  rgba(0, 0, 0, 0.8)
                );
              ">
            <mdb-card-body class="mx-4">
              <!--Header-->
              <br />
              <div class="text-center animated rollIn">
                <img style="width: 29%" src="/assets/images/Certiplate_Logo.svg" />
              </div>
              <br />

              <div id="warning" class="text-center" style="
                    background: linear-gradient(
                      rgba(0, 0, 0, 0.3),
                      rgba(0, 0, 0, 0.3)
                    );
                    background-color: red;
                    color: white;
                  "></div>
              <br />

              <form>
                <div class="md-form">
                  <mdb-icon fas icon="user" class="prefix dark-grey-text"></mdb-icon>
                  <input type="text" id="username" (keyup.enter)="test()" class="form-control"
                    formControlName="elegantFormEmailEx" mdbInput />
                  <label for="elegantFormEmailEx">Your Login ID</label>
                </div>

                <div class="md-form pb-3">
                  <mdb-icon fas icon="lock" class="prefix dark-grey-text"></mdb-icon>
                  <input type="password" id="pwd" (keyup.enter)="test()" formControlName="elegantFormPasswordEx"
                    class="form-control" mdbInput />
                  <label for="elegantFormPasswordEx">Your password</label>
                </div>

                <div class="md-form pb-0"></div>
                <!-- <div class="d-flex justify-content-end mb-1 pb-2 ">
                  <a style="color: rgb(148, 144, 144);" routerLink="/forgotpassword">Forgot Password?</a>
                </div> -->
                <div class="spinner-border text-success mb-3" style="
                      display: none;
                      position: relative;
                      top: 59.5%;
                      left: 47%;
                    " id="forgot-password"></div>


                <div class="text-center mb-3 animated bounceIn">
                  <button style="border-radius: 20px" type="button" mdbBtn data-toggle="modal"
                    data-target="#basicExampleModal" id="login" gradient="aqua" block="true" rounded="true"
                    class="waves-light" mdbWavesEffect (click)="test()">
                    LOGIN
                  </button>
                </div>
                <div class="spinner-border text-success mb-3" style="
                      display: none;
                      position: relative;
                      top: 59.5%;
                      left: 47%;
                    " id="log-in"></div>


              </form>
              <!--Body-->
            </mdb-card-body>
            <div class="modal-footer mx-5 pt-3 mb-1 justify-content-center">
              <p class="font-small grey-text d-flex">
                © 2012-2022,Copyright. All rights reserved: Powered by Certiplate.
                <!-- <a
                    href="https://navriti.com/"
                    class="grey-text ml-1"
                    target="_blank"
                    ><b> Navriti Technologies Pvt Ltd. </b></a
                  > -->
              </p>
            </div>
          </mdb-card>
        </section>
      </div>
    </div>
  </div>

  <div class="modal fade right" id="mymodal" style="background: black" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <!-- Add class .modal-full-height and then add class .modal-right (or other classes from list above) to set a position to the modal -->
    <div class="modal-dialog modal-half-height modal-right" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title w-100" id="myModalLabel">NOTIFICATION</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="position: relative; top: 35%; left: 15%">
          <h2>Your assessment is alredy in progress.</h2>
        </div>
        <div class="modal-footer justify-content-center">
          <button style="border-radius: 20px" id="ok" type="button" mdbBtn gradient="aqua" data-dismiss="modal"
            (click)="clicked()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- JQuery -->
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <!-- Bootstrap tooltips -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"></script>
  <!-- Bootstrap core JavaScript -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- MDB core JavaScript -->
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"></script>
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/notify/0.4.2/notify.js"></script>

  <!-- Your custom scripts (optional) -->
</body>

</html>