import { environment } from "./../../environments/environment";
import { Observable } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BlockPopupComponent } from "../block-popup/block-popup.component";
import { Router } from "@angular/router";
declare var window: any;
var CandidateData: any;

@Injectable({
  providedIn: "root",
})
export class SynDatewithServerService implements OnDestroy {
  id: any;
  blockedMessage: any;

  constructor(public dialog: MatDialog, private route: Router) {}

  openDialog(): void {
    let dialogRef = this.dialog.open(BlockPopupComponent, {
      width: "500px",
      height: "233px",
      data: { message: this.blockedMessage },
      disableClose: true,
      panelClass: "block-class",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.route.navigate(["login"]);
      this.clearAsyc();
    });
  }

  getData() {
    let candId = localStorage.getItem("CandidateId");
    let reqId = localStorage.getItem("req_id");
    let pass = localStorage.getItem("cand_id");
    let obj = JSON.parse(
      localStorage.getItem(reqId + "_" + pass + "_" + "data")
    );
    return obj;
  }


  
  startAsyc() {
    if (!this.id) {
      let candId = localStorage.getItem("CandidateId");
      let reqId = localStorage.getItem("req_id");
      let pass = localStorage.getItem("cand_id");
      let obj = JSON.parse(
        localStorage.getItem(reqId + "_" + pass + "_" + "data")
      );

      if (candId && reqId && obj) {
        if (obj.CandidateAssessmentData.SyncLiveAssessmentOnServer) {
          this.id = setInterval(() => {
            let updatedObj = this.getData();

            if (updatedObj !== null) {
              let data = {
                CandidateId: updatedObj.CandidateAssessmentData.CandidateId,
                RequestId:
                  updatedObj.CandidateAssessmentData.AssessmentRequestId,
                CandidateAssessmentData: updatedObj.CandidateAssessmentData,
              };

              localStorage.setItem("Response_data", JSON.stringify(updatedObj));

              this.savedate(data);
            }
          }, 10000);
        }
      }
    }
  }

  savedate(data: any) {
    this.uploadImages(data);

    let candId = localStorage.getItem("CandidateId");
    let reqId = localStorage.getItem("req_id");
    let pass = localStorage.getItem("cand_id");
    var self = this;
    var obj = localStorage.getItem(reqId + "_" + pass + "_" + "data");
    $.ajax({
      url: environment.URL_SYNC_ASSESSMENT_DATA,
      type: "POST",
      dataType: "json",
      data: JSON.stringify(data),
      contentType: false,
      cache: false,
      processData: false,
      async: false,
      success: function (response) {
        const success = response;

        if (success.SyncCandidateAssessmentData.IsAssessmentBlocked === true) {
          this.blockedMessage =
            success.SyncCandidateAssessmentData.AssessmentBlockedMessage;
          self.openDialog();
        }
      },
      error: function (e) {},
    });
  }

  clearAsyc() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  uploadImages(data: any) {
    CandidateData = data;
    window.requestFileSystem =
      window.requestFileSystem || window.webkitRequestFileSystem;
    window.requestFileSystem(
      window.TEMPORARY,
      100 * 1024 * 1024,
      this.onInitSyn
    );
  }

  async onInitSyn(fs?: any) {
    let data = CandidateData;
    let assementdate = data.CandidateAssessmentData;

    if (
      assementdate.TheoryAssessment.AssessmentStatusId ==
      environment.ASSESSMENT_IN_PROGRESS
    ) {
      if (
        data.CandidateAssessmentData.TheoryAssessment.ScreenshotImages.length
      ) {
        $.each(
          data.CandidateAssessmentData.TheoryAssessment.ScreenshotImages,
          function (index, value) {
            ReadFileFromFileSystem(fs, value.Filename);
          }
        );
      }

      if (
        !sessionStorage.getItem("NoWebCamFound") &&
        data.CandidateAssessmentData.TheoryAssessment.WebcamMandatory &&
        data.CandidateAssessmentData.TheoryAssessment.SnapshotImages.length
      ) {
        $.each(
          data.CandidateAssessmentData.TheoryAssessment.SnapshotImages,
          function (index, value) {
            ReadFileFromFileSystem(fs, value.Filename);
          }
        );
      }

      if (
        !sessionStorage.getItem("NoWebCamFound") &&
        data.CandidateAssessmentData.TheoryAssessment.WebcamMandatory &&
        data.CandidateAssessmentData.TheoryAssessment.CandidVideoFiles.length
      ) {
        $.each(
          data.CandidateAssessmentData.TheoryAssessment.CandidVideoFiles,
          function (index, value1) {
            if (value1.FileName != "") {
              ReadVideoFromFileSystem(fs, value1.FileName);
            }
          }
        );
      }

      // $('#loads').css('display', 'none');
      // $('#progresses').css('display', 'none');
      // $('#dones').css('display', 'block');
    }
  }
}

function ReadVideoFromFileSystem(varFs: any, fileName: any) {
  var firefox = myBrowser();
  if (firefox === "Firefox") {
    return false;
  } else {
    varFs.root.getFile(
      fileName,
      {},
      function (fileEntry: any) {
        // Get a File object representing the file,
        // then use FileReader to read its contents.

        fileEntry.file(function (file: any) {
          var reader = new FileReader();

          reader.onloadend = function (e: any) {
            var videovarFormData = new FormData();

            videovarFormData.append("video_file_name", fileName);

            videovarFormData.append("video_data", this.result as string);

            videovarFormData.getAll("video_file_name"); // ["true", "74", "John"]

            const res = Uploadfiles(videovarFormData);
          };
          reader.readAsText(file);
        }, errorHandler);
      },
      errorHandler
    );
  }
}
function errorHandler(err: any) {
  console.log(err);
}

function myBrowser() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "IE";
  } else {
    return "unknown";
  }
}
function ReadFileFromFileSystem(varFs: any, fileName: any) {
  let firefox = myBrowser();

  if (firefox === "Firefox") {
    return false;
  } else {
    varFs.root.getFile(
      fileName,
      {},
      function (fileEntry: any) {
        // Get a File object representing the file,
        // then use FileReader to read its contents.
        fileEntry.file(function (file: any) {
          var reader = new FileReader();
          reader.onloadend = function (e: any) {
            var varFormData = new FormData();
            varFormData.append("image_file_name", fileName);
            varFormData.append("image_data", this.result as string);
            const res = Uploadfiles(varFormData);
          };
          reader.readAsText(file);
        }, errorHandler);
      },
      errorHandler
    );
  }
}

function Uploadfiles(varFormData: any) {
  $.ajax({
    url: environment.Upload_files_URL,
    type: "POST",
    data: varFormData,
    contentType: false,
    cache: false,
    processData: false,
    async: false,
    success: function (response) {},
    error: function (e) {
      alert("Error");
    },
  });
}
