import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-block-popup',
  templateUrl: './block-popup.component.html',
  styleUrls: ['./block-popup.component.css']
})
export class BlockPopupComponent  {

  blockMessage:string
  constructor(public dialogRef: MatDialogRef<BlockPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      console.log('datadata',data);
      this.blockMessage = data.message ? data.message : 'Your account might be blocked.'
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
