<!DOCTYPE html>
<html>
<head>
	
	<meta charset="utf-8">
	<meta name="viewreport" content="width=device-width, initial-scale=1.0">
	<title>
		HOME
	</title>
	<meta charset="UTF-8">

	<link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
	<link rel="stylesheet" type="text/css" href="candidate-list.component.css" />
  	<link rel="stylesheet" type="text/html" href="/resources/demos/style.css"/>

</head>

<body id="body">

	<app-header></app-header>
	<div class="col-md-12">				
			<app-navbar></app-navbar>
	</div>
  <div class="borderline"></div>
  
  <div class="title">
	<h4>CANDIDATE LIST</h4>
	<div style="display:inline-block;margin-right: 3%; margin-left:50%"  *ngIf="reqType=='PAST'; else elseBlock1" >
    <nav aria-label="breadcrumb">
      <ol style="background: transparent;" class="breadcrumb">
		<li *ngIf="UserRole=='3'; else elseBlock" class="breadcrumb-item">
			<a style="color: rgb(32, 32, 238);" [routerLink]="['/AssessorDetails']">Dashboard</a>
		</li>
		<ng-template #elseBlock>
			<a style="color: rgb(32, 32, 238);" [routerLink]="['/home']">Dashboard</a>
		</ng-template>
        <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/past_batches']" [queryParams]="{RequestType : 'PAST'}">Past Batches</a></li>
        <li class="breadcrumb-item active">Candidate List</li>
      </ol>
	</nav>
	</div>

	<ng-template #elseBlock1>
		<div style="display:inline-block;margin-right: 3%; margin-left:50%"  *ngIf="reqType=='TODAY'; else elseBlock2" >
		<nav aria-label="breadcrumb">
			<ol style="background: transparent;" class="breadcrumb">
			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/home']">Dashboard</a></li>
			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/todays_batches']" [queryParams]="{RequestType : 'TODAY'}">Todays Batches</a></li>
			  <li class="breadcrumb-item active">Candidate List</li>
			</ol>
		  </nav>
		</div>
	</ng-template>

	<ng-template #elseBlock2>
		<nav aria-label="breadcrumb" style="display:inline-block;margin-right: 3%; margin-left:50%" >
			<ol style="background: transparent;" class="breadcrumb">
				<li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/home']">Dashboard</a></li>
			  <li class="breadcrumb-item"><a style="color: rgb(32, 32, 238);" [routerLink]="['/upcoming_batches']" [queryParams]="{RequestType : 'UPCOMING'}">Upcoming Batches</a></li>
			  <li class="breadcrumb-item active">Candidate List</li>
			</ol>
		  </nav>
	</ng-template>
  </div>
			
	<div class="container-fluid banner">
			<div class="spinner-border text-primary" id="image" style="display: none"></div>
			  <table class="table-sortable" id="myTable">
			  <thead>
				  <tr>
					<th>S.No</th>
					<th>Candidate Name</th>
					<th>Registration ID</th>
                    <th>Contact Number</th>
          
                    <th>Gender</th>
					<th>Guardian Name</th>
					<th>Assessment </th>
					<th>Status</th>

					<th id="am">Assessment Media</th>
					<th>Assessment Log</th>
					<th>Evaluation</th>
					
				</tr>
				</thead>

				<tbody>
					<ng-container *ngFor="let d of userData; let i = index">

						<tr *ngFor="let child of d.Assessments; let x = index;">
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{i+1}}</td>
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{d.CandidateName}}</td>
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{d.RegistrationId}}</td>
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{d.ContactNumber}}</td>
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{d.Gender}}</td>
							<td *ngIf="x == 0" [attr.rowspan]="d.Assessments.length">{{d.GuardianName}}</td>


							<td >{{child.AssessmentCategory}}</td>
							<td >{{child.AssessmentStatus}}</td>
							<td *ngIf="child.AssessmentCategory.toUpperCase() == 'THEORY' && child.AssessmentStatus.toUpperCase() == 'GRADED' || child.AssessmentStatus.toUpperCase() == 'EVALUATION PENDING'; else elseBlock4">
								<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'1',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Start/End Images" style="background-color:rgb(255, 207, 120); border-color:rgb(197, 197, 197);"><mat-icon >perm_identity</mat-icon></a>
								<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'2',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Identity Image" style="background-color:rgb(225, 153, 182); border-color:rgb(197, 197, 197);"><mat-icon>photo</mat-icon></a>
								<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'3',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View System Snapshots" style="background-color:rgb(91, 134, 138); border-color:rgb(197, 197, 197);"><mat-icon >party_mode</mat-icon></a>
								<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'4',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Snapshots" style="background-color:rgb(135, 135, 161); border-color:rgb(197, 197, 197);"><mat-icon >photo_camera</mat-icon></a>
							</td>
							<ng-template #elseBlock4>
								<td *ngIf="child.AssessmentCategory.toUpperCase() == 'PRACTICAL' &&  child.AssessmentStatus.toUpperCase() == 'EVALUATION PENDING'; else elseBlock9">
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'1',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Start/End Images" style="background-color:rgb(255, 207, 120); border-color:rgb(197, 197, 197);"><mat-icon >perm_identity</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'2',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Identity Image" style="background-color:rgb(225, 153, 182); border-color:rgb(197, 197, 197);"><mat-icon>photo</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'3',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View System Snapshots" style="background-color:rgb(91, 134, 138); border-color:rgb(197, 197, 197);"><mat-icon >party_mode</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'4',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Snapshots" style="background-color:rgb(135, 135, 161); border-color:rgb(197, 197, 197);"><mat-icon >photo_camera</mat-icon></a>
								</td>
							</ng-template>
							<ng-template #elseBlock9>
								<td style="padding: 4px 4px; text-align:left" *ngIf="child.AssessmentCategory.toUpperCase() == 'PRACTICAL' &&  child.AssessmentStatus.toUpperCase() == 'GRADED'; else elseBlock8">
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'1',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Start/End Images" style="background-color:rgb(255, 207, 120); border-color:rgb(197, 197, 197);"><mat-icon >perm_identity</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'2',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Identity Image" style="background-color:rgb(225, 153, 182); border-color:rgb(197, 197, 197);"><mat-icon>photo</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'3',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View System Snapshots" style="background-color:rgb(91, 134, 138); border-color:rgb(197, 197, 197);"><mat-icon >party_mode</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentImages(d.CandidateId,child.ScheduleId,child.AssessmentId,'4',child.ExamMode,child.AssessmentCategory, child.AssessmentStatus)"  title="View Candidate Snapshots" style="background-color:rgb(135, 135, 161); border-color:rgb(197, 197, 197);"><mat-icon >photo_camera</mat-icon></a>
									<a class="btn" (click)="ShowAssessmentVideos(d.CandidateId,child.ScheduleId,child.AssessmentId,child.ExamMode)"  title="View Response Videos" style="background-color:rgb(162, 70, 162); border-color:rgb(162, 70, 162);"><mat-icon >play_arrow</mat-icon></a>
								</td>
							</ng-template>

							<ng-template #elseBlock8>
								<td>--</td>
							</ng-template>

							<td *ngIf="child.AssessmentStatus.toUpperCase() == 'GRADED' || child.AssessmentStatus.toUpperCase() == 'EVALUATION PENDING'; else elseBlock5">
								<a class="btn" (click)="ShowAssessmentEvents(d.CandidateId,child.ScheduleId,child.AssessmentId,child.ExamMode)" title="View Assessment Events" style="background-color:rgb(157, 157, 157); border-color:rgb(197, 197, 197);"><mat-icon >event</mat-icon></a>
								<a class="btn" (click)="ShowSystemInfo(d.CandidateId,child.ScheduleId,child.AssessmentId,child.ExamMode)" title="View Candidate System Info" style="background-color:rgb(108, 199, 146); border-color:rgb(197, 197, 197);"><mat-icon>list</mat-icon></a>
							</td>
							<ng-template #elseBlock5>
								<td>--</td>
							</ng-template>


							<td *ngIf=" child.AssessmentStatus.toUpperCase() == 'EVALUATION PENDING'; else elseBlock6">
								<a class="btn" (click)="PendingEvaluation(d.CandidateId,child.ScheduleId,child.AssessmentId)" title="View Practical Assessment Evaluation" style="background-color:rgb(162, 70, 162); border-color:rgb(197, 197, 197);"><mat-icon >play_arrow</mat-icon></a>
							</td>
							<ng-template #elseBlock6>
								<td *ngIf=" child.AssessmentCategory.toUpperCase() == 'PRACTICAL' && child.AssessmentStatus.toUpperCase() == 'GRADED'; else elseBlock7">
									Completed
								</td>
							</ng-template>

							<ng-template #elseBlock7>
								<td>--</td>
							</ng-template>

						  
						</tr>
				  </ng-container>					
				</tbody>
			</table>
	</div>

	<div class="modal" id="myModal" role="dialog" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg ">
		  <div class="modal-content" style="width: 100vh;">
	  
			<!-- Modal Header -->
			<div class="modal-header" style="height: 8vh;">
			  <h3 id="hdrImageTitle" class="modal-title" style="color: black;"></h3>
			  <label id="lblTimeStamp" style="margin-right:24px;color:maroon;font-style:italic;"></label>

			</div>
	  
			<!-- Modal body -->
			<div style="color: black; height: auto;" class="modal-body">
			  <img id="imgPreview" style="height: 300px; margin-left:13%">
			</div>
	  
			<!-- Modal footer -->
			<div class="modal-footer justify-content-between" style="height: 8vh;">
				<div id="divImagePreviewControls">
				<a id="btnFirst" (click)="NavigateImage('FIRST')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#0055cc; border-color:#0055cc">First</a>
				<a id="btnPrevious" (click)="NavigateImage('PREVIOUS')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#51a351; border-color:#51a351">Previous</a>

				<a id="btnNext" (click)="NavigateImage('NEXT')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#51a351; border-color:#51a351">Next</a>
				<a id="btnLast" (click)="NavigateImage('LAST')" class="btn btn-success" data-dismiss="modal" style="display: inline; background-color:#0055cc; border-color:#0055cc">Last</a>
				</div>

				<div id="test">
				<a id="btnViewMap" (click)="ViewImageLocation()" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#faa732; border-color:#faa732">View Map</a>
				<a class="btn btn-success" (click)="hideModel()" data-dismiss="modal" style="display: inline;">Close</a>
			</div>
			</div>
	  
		  </div>
		</div>
	  </div>




	  <div class="modal" id="popupVideoPreview" role="dialog" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg ">
		  <div class="modal-content" style="width: 100vh;">
	  
			<!-- Modal Header -->
			<div class="modal-header" style="height: 8vh;">
			  <h3 id="hdrVideoTitle" class="modal-title" style="color: black;">Videos</h3>
			</div>
	  
			<!-- Modal body -->
			<div class="modal-body">

				<div style="clear:both;"></div>
            	<div class="row-fluid control-group">
                <div class="row-fluid">
                    <label class="span2" >Section : </label>
                    <div class="span10" >
                        <input type="text" id="txtSectionName" name="txtSectionName" class="input-xxlarge" disabled="disabled" value=""/>
                    </div>
                    <div style="clear:both;"></div>
                    <label class="span2">Question : </label>
                    <div class="span10">
                        <textarea id="txtQuestionText" class="input-xxlarge" disabled="disabled"></textarea>
                    </div>
                    <label class="span2">Video : </label>
                    <div class="span10" >
                        <img id="imgVideoPreview"/>
                        <video id="videoPreview" class="input-xxlarge" width="480" height="270" controls ></video>
                    </div>
                </div>
                <div style="clear:both;"></div>
                <div class="controls"></div>
            </div>


			</div>
	  
			<!-- Modal footer -->
			<div class="modal-footer justify-content-between" style="height: 8vh;">
				<div id="divVideoPreviewControls">
				<a id="btnFirst" (click)="NavigateVideos('FIRST')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#0055cc; border-color:#0055cc">First</a>
				<a id="btnPrevious" (click)="NavigateVideos('PREVIOUS')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#51a351; border-color:#51a351">Previous</a>

				<a id="btnNext" (click)="NavigateVideos('NEXT')" class="btn btn-success" data-dismiss="modal" style="display: inline; margin-right:5px; background-color:#51a351; border-color:#51a351">Next</a>
				<a id="btnLast" (click)="NavigateVideos('LAST')" class="btn btn-success" data-dismiss="modal" style="display: inline; background-color:#0055cc; border-color:#0055cc">Last</a>
				</div>

				<div id="test">
				<a class="btn btn-success" (click)="hideModel()" data-dismiss="modal" style="display: inline;">Close</a>
			</div>
			</div>
	  
		  </div>
		</div>
	  </div>



	  <div class="modal" id="popupSystemInfo" role="dialog" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg ">
		  <div class="modal-content" style="width: 100vh; font-size:12px;">
	  
			<!-- Modal Header -->
			<div class="modal-header" style="height: 8vh;">
			  <h3 class="modal-title" style="color: black;">Candidate System Info</h3>
			  <a class="close" [routerLink]="" (click)="hideModel()">X</a>
			</div>
	  
			<!-- Modal body -->
			<div style="color: black; height:80vh; overflow-y:scroll" class="modal-body">
			  
				<div class="row-fluid control-group">
					<table id="tblSystemInfo" class="display table table-bordered"  style="display:none;width:90vh;overflow:scroll">                        
						<tbody>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Info DateTime</td>
								<td><label id="lblSystemInfoDateTime"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Computer Name</td>
								<td><label id="lblComputerName"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Domain</td>
								<td><label id="lblComputerDomain"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">IP Address</td>
								<td><label id="lblIpAddress"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Location</td>
								<td id="tdLocation"></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">OS</td>
								<td><label id="lblOSName"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">OS  Version</td>
								<td><label id="lblOSVersion"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">OS Manufacturer</td>
								<td><label id="lblOSManufacturer"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">OS Configuration</td>
								<td><label id="lblOSConfiguration"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">OS Build Type</td>
								<td><label id="lblOSBuildType"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Product ID</td>
								<td><label id="lblProductId"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Manufacturer</td>
								<td><label id="lblSystemManufacturer"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Model</td>
								<td><label id="lblSystemModel"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Type</td>
								<td><label id="lblSystemType"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Processor</td>
								<td><label id="lblProcessor"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">BIOS Version</td>
								<td><label id="lblBIOSVersion"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Locale</td>
								<td><label id="lblSystemLocale"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Time Zone</td>
								<td><label id="lblTimeZone"></label></td>
							</tr>
						</tbody>
					</table> 
	
					<table id="tblSystemInfo2" class="display table table-bordered" style="display:none;">                        
						<tbody>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">System Info DateTime</td>
								<td><label id="lblDeviceSystemInfoDateTime"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Manufacturer</td>
								<td><label id="lblDeviceManufacturer"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Model</td>
								<td><label id="lblDeviceModel"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Hardware</td>
								<td><label id="lblDeviceHardware"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Location</td>
								<td id="tdDeviceLocation"></td>
							</tr>
							
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Tags</td>
								<td><label id="lblDeviceTags"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Type</td>
								<td><label id="lblDeviceType"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">SDK Version</td>
								<td><label id="lblDeviceSdkVersion"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">App Version</td>
								<td><label id="lblDeviceAppVersion"></label></td>
							</tr>
							<tr>
								<td style="background-color:rgb(247, 247, 247);width:30%;">Android Version</td>
								<td><label id="lblDeviceAndroidVersion"></label></td>
							</tr>                        
						</tbody>
					</table> 
				</div>


			</div>
	  
			<!-- Modal footer -->
			<div class="modal-footer" style="height: 8vh;">	
				<a class="btn btn-success"  (click)="hideModel()" data-dismiss="modal" style="float:right">Close</a>
			</div>
	  
		  </div>
		</div>
	  </div>
	 
	  

	  <div class="modal" id="popupEventPreview" role="dialog" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg ">
		  <div class="modal-content" style="width: auto;font-size:14px;">
	  
			<!-- Modal Header -->
			<div class="modal-header" style="height: 8vh;">
			  <h3 id="hdrImageTitle" class="modal-title" style="color: black;">Assessment Log (Raw Format)</h3>
			</div>
	  
			<!-- Modal body -->
			<div style="color: black; height:80vh; overflow-y:scroll" class="modal-body">

				<div class="row-fluid control-group">
					<table id="tblEvents" class="display table table-bordered">
							<thead class="overrideBackgroundColor">  
								<tr>
									<th>Log Date Time</th>
									<th>Event Type</th>
									<th>Event Subtype</th>
									<th>Event Description</th>
									<th>Section</th>
									<th>Question</th>
									<th>Option</th>
									<th>Elapsed Seconds</th>                                
									<th>Image</th>
									<th>Location</th>
								</tr>
							</thead>
							<tbody id="tblEventBody">
							</tbody>
						</table> 
				</div>

			</div>
	  
			<!-- Modal footer -->
			<div class="modal-footer" style="height: 8vh;">	
				<a class="btn btn-success"  (click)="hideModel()" data-dismiss="modal" style="float:right">Close</a>
			</div>
	  
		  </div>
		</div>
	  </div>
	

	</body>

</html>