<app-nav-bar></app-nav-bar>

<button mdbBtn id="btn1" class="btn purple-gradient actionBtn btnWebcam" (click)="triggerSnapshot('btn1')">
  Capture End Face Image
</button>

<webcam class="webcamEndImage" id="webcam" [height]="400" [width]="400" (initError)="handleInitError($event)"
  [trigger]="triggerObservable" (imageCapture)="handleImage1($event)"></webcam>

<h1 class="animated rubberBand faceImagetitle" *ngIf="webcamImage1">
  FACE IMAGE
</h1>
<div class="snapshot faceImageDiv" *ngIf="webcamImage1">
  <img [src]="webcamImage1.imageAsDataUrl" class="faceImage" />
</div>

<button mdbBtn id="submit_button" class="btn blue-gradient animated swing actionBtn submitbtn" *ngIf="webcamImage1"
  (click)="clicked()">
  Submit and Proceed
</button>
<form style="display: none" id="frmImages">
</form>
<app-footer></app-footer>