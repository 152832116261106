<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8" />
  <meta name="viewreport" content="width=device-width, initial-scale=1.0" />
  <title>HOME</title>
  <meta charset="UTF-8" />

  <link rel="stylesheet" href="https://cdn.datatables.net/1.10.19/css/dataTables.bootstrap4.min.css" />
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" />

  <link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
  <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
  <link rel="stylesheet" type="text/css" href="change-password.component.css" />
</head>

<body>
  <div class="col-md-6 my-3 mx-auto pt-5">
    <section class="form-elegant w3-animate-zoom mt-3 pt-3"
      style="position: fixed; bottom: 25%; top: 15%; left: 26%; width: 700px">
      <mdb-card class="mx-4 pt-3 pb-0" style="
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
          ">
        <mdb-card-body class="mx-4 pt-3 pb-0 mt-0">
          <!--Header-->
          <br />
          <div class="text-center">
            <img src="/assets/images/image.png" />
          </div>
          <br />
          <div class="text-center pb-0">
            <p style="color: rgb(240, 232, 232)">
              Please enter you old password and new password
            </p>
          </div>

          <div id="warning" class="text-center" style="color: red"></div>
          <br />
          <form>
            <div class="md-form pt-0 pb-0">
              <mdb-icon fas icon="lock" class="prefix dark-grey-text"></mdb-icon>
              <input style="opacity: 0.8" type="password" id="oldpassword" class="form-control"
                formControlName="elegantFormPasswordEx" mdbInput required />
              <label for="elegantFormPasswordEx">Your old password</label>
            </div>

            <div class="md-form pb-0">
              <mdb-icon fas icon="lock" class="prefix dark-grey-text"></mdb-icon>
              <input style="opacity: 0.8" type="password" id="newpassword" formControlName="elegantFormPasswordEx"
                class="form-control" mdbInput />
              <label for="elegantFormPasswordEx">Your new password</label>
            </div>

            <div class="md-form pb-0">
              <mdb-icon fas icon="lock" class="prefix dark-grey-text"></mdb-icon>
              <input style="opacity: 0.8" type="password" id="confnewpassword" formControlName="elegantFormPasswordEx"
                class="form-control" mdbInput />
              <label for="elegantFormPasswordEx">Confirm password</label>
            </div>
            <div class="d-flex justify-content-end mb-1 pb-2 mt-0">
              <a style="color: white; align-content: flex-end; cursor: pointer" (click)="clicked()">Back to Previous
                Page</a>
            </div>
            <div class="text-center pt-0 mt-0 mb-4 animated bounceIn">
              <button style="border-radius: 20px" type="button" mdbBtn data-toggle="modal"
                data-target="#basicExampleModal" id="changepassword" block="true" rounded="true"
                (click)="updatepassword()">
                CHANGE PASSWORD
              </button>
            </div>
            <div class="spinner-border text-success mb-3"
              style="display: none; position: relative; top: 59.5%; left: 47%" id="log-in"></div>
          </form>
          <!--Body-->
        </mdb-card-body>
      </mdb-card>
    </section>
  </div>

  <div class="modal-footer mx-5 pt-3 mb-1 justify-content-center fixed-bottom">
    <p style="color: rgb(0, 0, 0)" class="font-small d-flex">
      © 2012-2022,Copyright. All rights reserved: Powered by Certiplate.
      <!-- <a
          style="color: rgb(0, 0, 0)"
          href="https://navriti.com/"
          class="ml-1"
          target="_blank"
          ><b> Navriti Technologies Pvt Ltd. </b></a
        > -->
    </p>
  </div>
</body>

</html>