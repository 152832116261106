<app-nav-bar></app-nav-bar>

<div style="top: 8%; position: absolute; width: 100%; display: block" class="card col-md-12 mb-4 text-center">
  <div class="card-body">
    <h1 class="card-title animated heartBeat">Theory Assessment Feedback</h1>
    <div class="row justify-content-center">
      <label style="color: black" id="1" class="card-text"></label><span class="required">*</span><br />
    </div>
    <div class="row justify-content-center">
      <textarea style="border: 2px solid black" type="text" id="text" rows="3" cols="100" required></textarea>
      <br />
    </div>
    <hr />

    <div class="row justify-content-center">
      <label style="color: black" id="2" class="card-text"></label><span class="required">*</span><br />
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" value="0" style="vertical-align: middle" class="custom-control-input" id="Group1"
        name="groupOfDefaultRadios1" />
      <label style="color: black" id="opt1_1" class="custom-control-label" for="Group1"></label>
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" class="custom-control-input" value="1" style="vertical-align: middle" id="Group2"
        name="groupOfDefaultRadios1" />
      <label style="color: black" id="opt1_2" class="custom-control-label" for="Group2"></label>
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" class="custom-control-input" value="2" style="vertical-align: middle" id="Group3"
        name="groupOfDefaultRadios1" />
      <label style="color: black" id="opt1_3" class="custom-control-label" for="Group3"></label>
    </div>
    <div class="custom-control custom-radio">
      <input type="radio" class="custom-control-input" value="3" style="vertical-align: middle" id="Group4"
        name="groupOfDefaultRadios1" />
      <label style="color: black" id="opt1_4" class="custom-control-label" for="Group4"></label>
    </div>
    <hr />

    <div class="row justify-content-center">
      <label style="color: black" id="3" class="card-text"></label><span class="required">*</span><br />
    </div>
    <div class="justify-content-center">
      <select name="groupOfDefaultRadios2" class="custom-select custom-select-sm" style="width: 50%">
        <option selected>Open this select menu</option>
        <option name="groupOfDefaultRadios2" value="0" id="opt2_1"></option>
        <option name="groupOfDefaultRadios2" value="1" id="opt2_2"></option>
        <option name="groupOfDefaultRadios2" value="2" id="opt2_3"></option>
        <option name="groupOfDefaultRadios2" value="3" id="opt2_4"></option>
        <option name="groupOfDefaultRadios2" value="4" id="opt2_5"></option>
      </select>
    </div>
    <br />
    <hr />

    <div class="row justify-content-center">
      <label style="color: black" id="4" class="card-text"></label><span class="required">*</span><br />
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" style="vertical-align: middle" value="0" id="Group1_b"
        name="groupOfDefaultRadios3" />
      <label style="color: black" id="opt3_1" class="custom-control-label" for="Group1_b"></label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" style="vertical-align: middle" value="1" id="Group2_b"
        name="groupOfDefaultRadios3" />
      <label style="color: black" id="opt3_2" class="custom-control-label" for="Group2_b"></label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" style="vertical-align: middle" value="2" id="Group3_b"
        name="groupOfDefaultRadios3" />
      <label style="color: black" id="opt3_3" class="custom-control-label" for="Group3_b"></label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" style="vertical-align: middle" value="3" id="Group4_b"
        name="groupOfDefaultRadios3" />
      <label style="color: black" id="opt3_4" class="custom-control-label" for="Group4_b"></label>
    </div>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" style="vertical-align: middle" value="4" id="Group5_b"
        name="groupOfDefaultRadios3" />
      <label style="color: black" id="opt3_5" class="custom-control-label" for="Group5_b"></label>
    </div>
    <hr />

    <div class="row justify-content-center">
      <label style="color: black" id="5" class="card-text"></label><span class="required">*</span><br />
    </div>
    <div class="justify-content-center">
      <select name="groupOfDefaultRadios4" class="custom-select custom-select-sm" style="width: 50%" multiple>
        <option value="0" id="opt4_1"></option>
        <option value="1" id="opt4_2"></option>
        <option value="2" id="opt4_3"></option>
        <option value="3" id="opt4_4"></option>
        <option value="4" id="opt4_5"></option>
        <option value="5" id="opt4_6"></option>
      </select>
    </div>

    <br>
    <div class="row justify-content-center">

      <div class="justify-content-center">
        <button id="next" mdBtn class="btn btn-amber btnNext" (click)="clicked()" disabled>
          Next
        </button>
      </div>

    </div>
  </div>
  <br />

</div>
<br>
<br>

<app-footer></app-footer>