<app-nav-bar></app-nav-bar>
<div class="text-center">
  <h1 class="animated heartBeat submitText">
    SUBMIT ASSESSMENT
  </h1>
  <div class="card responseDatadiv1">
    <div class="card-body align-items-center d-flex justify-content-center responseDatadiv2">
      <h2 class="card-title" style="margin: 3px; color: black">
        <b>Response Data Upload:</b>
      </h2>
      <br />
      <div id="load" class="spinner-border text-primary spinnerBorder"></div>
      <h2 id="progress" class="loaderText1">
        In progress...
      </h2>
      <i id="done" class="fa fa-check fa-lg spinnerSubmit1">
        Done</i>
    </div>
  </div>



  <div class="responseFilediv1">
    <h2 style="margin: 3px; color: black">
      <b>Response File Upload :</b>
    </h2>
    <div id="loads" class="spinner-border text-primary responseFilediv2"></div>
    <h2 id="progresses" class="loaderText2">
      In progress...
    </h2>
    <i id="dones" class="fa fa-check fa-lg spinnerSubmit2">
      Done</i>
  </div>

  <div class="completedText">
    <a id="btn" class="btn aqua-gradient animated swing" style="display: none" (click)="assessment()">Back to Assessment
      Details Page</a>
    <a id="success" class="btn aqua-gradient animated swing" style="display: none" (click)="finished()">Successfully
      Completed</a>
  </div>
  <div id="div" class="text-center justify-content-center warning">
    <h2 id="warning"></h2>
  </div>
</div>
<form style="display: none" id="frmImages"></form>

<app-footer></app-footer>