<div class="logo ml-5 mt-3" id="logo">
  <a href="https://navriti.com/"><img src="assets/images/certlogo.png"/></a>
</div>

<div class="top">
      <ul class="nav navbar-right mr-5 mt-3">

          <ul class="nav navbar-right mt-0 pt-0 mr-3">	
              <li *ngIf="UserRole=='3'; else elseBlock">
                  <a style="color: white; border-radius: 4px;" class="nav-link" routerLinkActive="active" routerLink="/AssessorDetails">DASHBOARD</a>
              </li>
              <ng-template #elseBlock>
                  <a style="color: white; border-radius: 4px;" class="nav-link" routerLinkActive="active" routerLink="/home">DASHBOARD</a>
              </ng-template>
              <!--<li>
                  <a style="color: white;" class="nav-link" routerLinkActive="active" [routerLink]="['/batches/todays_batches']" [queryParams]="{RequestType : 'TODAY'}" >BATCHES</a>
              </li>-->
              <li class="nav-item dropdown">
                      <a style="color: white; border-radius: 4px;" class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">BATCHES
                          <ul style="width: auto;" class="dropdown-menu fade-up">
                              <li><a href="#" style="color: white; padding:7px 7px;" class="dropdown-item" [routerLink]="['/todays_batches']" [queryParams]="{RequestType : 'TODAY'}">TODAY'S BATCHES</a></li>
                              <li><a href="#" style="color: white; padding:7px 7px;" class="dropdown-item" [routerLink]="['/past_batches']" [queryParams]="{RequestType : 'PAST'}">PAST BATCHES</a></li>
                              <li><a href="#" style="color: white; padding:7px 7px;" class="dropdown-item" [routerLink]="['/upcoming_batches']" [queryParams]="{RequestType : 'UPCOMING'}">UPCOMING BATCHES</a></li>
                          </ul>
                      </a>
              </li>
              <li>
                  <a id="bills" class="nav-link" routerLinkActive="active" routerLink="/bills">BILLS</a>
              </li>
              <li>
                  <a id="reports" class="nav-link" routerLinkActive="active" routerLink="/reports">REPORTS</a>
              </li>
          </ul>

          <li style="background-color: rgba(0, 0, 0, 0.8); border-radius:4px;" class="nav-item dropdown">
              <a style="font-size: 15px;  color:white ;" class="nav-link  dropdown-toggle" href="#" data-toggle="dropdown">
                  {{username}}</a>
              <ul style="background-color: black; " class="dropdown-menu fade-up">
                  <li ><a style="color: white; padding:15px;" class="dropdown-item" routerLink="/changepassword">Change Password</a></li>
                  <li id="up" ><a class="dropdown-item" routerLink="/updateprofile">Update Profile</a></li>
                  <li ><a style="color: white; padding:15px;" class="dropdown-item" routerLink="/login" (click)="clear()">Logout</a></li>
              </ul>
          </li>
      </ul>
      
</div>
