<app-nav-bar></app-nav-bar>

<button mdbBtn id="btn1" class="btn purple-gradient actionBtn facebtn" (click)="triggerSnapshot('btn1')">
  Take Face Image Snapshot
</button>
<button mdbBtn id="btn2" class="btn purple-gradient actionBtn idbtn" (click)="triggerSnapshot('btn2')">
  Take ID Image Snapshot
</button>

<webcam class="webCamuser" id="webcam" [height]="400" [width]="400" (initError)="handleInitError($event)"
  [trigger]="triggerObservable" (imageCapture)="handleImage1($event)"></webcam>

<h1 class="animated rubberBand faceimgtitle" *ngIf="webcamImage1">
  FACE IMAGE
</h1>
<div class="snapshot faceimgdiv" *ngIf="webcamImage1">
  <img class="faceimgwidth" id="face_image" [src]="webcamImage1.imageAsDataUrl" />
</div>




<h1 class="animated rubberBand idimgtitle" *ngIf="webcamImage2">
  ID Card IMAGE
</h1>
<div class="snapshot1 idimgdiv" *ngIf="webcamImage2">
  <img id="id_image" [src]="webcamImage2.imageAsDataUrl" class="idimgwidth" />
</div>



<button mdbBtn id="submit_button" class="btn blue-gradient animated swing actionBtn submitBtn"
  *ngIf="webcamImage1 && webcamImage2" (click)="clicked()">
  Submit and Proceed
</button>
<div class="spinner-border text-primary mb-3" style="display: none; position: absolute; top: 82.5%; left: 86%"
  id="move-in"></div>
<form style="display: none" id="frmImages"></form>
<app-footer></app-footer>