<dl class="row">
   <dt class="col-sm-3">Candidate Name :</dt>
   <dd class="col-sm-9">{{ candidateDate.CandidateName }} </dd>

   <dt class="col-sm-3">Registration Id </dt>
   <dd class="col-sm-9">
      {{candidateDate.RegistrationId }}
   </dd>

   <dt class="col-sm-3">Job </dt>
   <dd class="col-sm-9">
      {{candidateDate.QualificationPackName }}
   </dd>

   <dt class="col-sm-3">Section </dt>
   <dd class="col-sm-9">
      {{candidateDate.TheoryAssessment.Sections.length }}
   </dd>
   <dt class="col-sm-3">Duration </dt>
   <dd class="col-sm-9">
      {{minutes }} m : {{ seconds }} s
   </dd>
   <dt class="col-sm-3">Question Paper Title</dt>
   <dd class="col-sm-9">
      {{candidateDate.QuestionPaperTitle }}
   </dd>
</dl>