
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <title>LOGIN Page</title>
    <!-- MDB icon -->
    <link rel="icon" href="img/mdb-favicon.ico" type="image/x-icon" />
    <!-- Font Awesome -->
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.11.2/css/all.css"
    />
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
    <!-- Google Fonts Roboto -->
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <!-- Font Awesome -->

    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css"
      rel="stylesheet"
    />
    <!-- Material Design Bootstrap -->
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css"
      rel="stylesheet"
    />
  </head>
  <body>
    <div class="bg">
      <div class="row">




        <div class="col-md-12">
          					
              <ul class="nav navbar-left ml-0">
                  <li class="nav-item">
                      <a href="https://navriti.com/"><img src="assets/images/certlogo.png"/></a>
                  </li>
              </ul>
          
      </div>    



        <div class="col-md-6 mx-auto my-5">
          <section
            class="form-elegant w3-animate-zoom"
            style="position: fixed; top: 28%; left: 35%; width: 500px">
            <mdb-card
              style="
                background: linear-gradient(
                  rgba(0, 0, 0, 0.7),
                  rgba(0, 0, 0, 0.7)
                );
              "
            >
              <mdb-card-body class="mx-3 my-2 mt-0">
                <!--Header-->
                <br />
                <div class="text-center">
                  <img src="/assets/images/image.png" />
                </div>
                <br />

                <div
                  id="warning"
                  class="text-center"
                  style="
                    color: red;
                  "
                ></div>
                <br />
                <div class="text-center mb-5">
                    <p style = "color: rgb(240, 232, 232);">Email Sent successfully!</p>
                </div>
                <!--Body-->
              </mdb-card-body>
              
            </mdb-card>
          </section>
        </div>
      </div>



      <app-footer></app-footer>


    </div>
    <!-- JQuery -->
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    ></script>
    <!-- Bootstrap tooltips -->
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"
    ></script>
    <!-- Bootstrap core JavaScript -->
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"
    ></script>
    <!-- MDB core JavaScript -->
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"
    ></script>
    <script
      type="text/javascript"
      src="https://cdnjs.cloudflare.com/ajax/libs/notify/0.4.2/notify.js"
    ></script>

    <!-- Your custom scripts (optional) -->
  </body>
</html>
