import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successful-email-sent',
  templateUrl: './successful-email-sent.component.html',
  styleUrls: ['./successful-email-sent.component.css']
})
export class SuccessfulEmailSentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
