import { environment } from "./../../environments/environment";
import { Router } from "@angular/router";
import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import * as moment from "moment";
import { Observable, Observer, fromEvent, merge, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import * as os from "os";
import { SynDatewithServerService } from "../service/syn-datewith-server.service";

declare var window: any;
var total_count = 0,
  count = 0;
var response_object: any;

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Component({
  selector: "app-submit-response",
  templateUrl: "./submit-response.component.html",
  styleUrls: ["./submit-response.component.css"],
})
export class SubmitResponseComponent implements OnInit, OnDestroy {
  ip: any;

  constructor(
    private synDatewithServerService: SynDatewithServerService,
    private route: Router,
    private deviceservice: DeviceDetectorService
  ) {}

  Req: any;
  Id: any;
  isFirefox: any;
  ipAddress: any;
  localIp: any;

  async ngOnInit(): Promise<any> {
    const request = await fetch("https://ipinfo.io/json?token=91e835c18c67cc");
    const json = await request.json();
    this.ip = json.ip;
    this.Req = localStorage.getItem("req_id");
    this.Id = localStorage.getItem("cand_id");
    this.synDatewithServerService.startAsyc();
    let data = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_data")
    );
    this.isFirefox = myBrowser();
    if (localStorage.getItem("assessment") == "theory") {
      this.getSystemInfo("TheoryAssessment");
    } else if (localStorage.getItem("assessment") == "practical") {
      this.getSystemInfo("PracticalAssessment");
    } else if (localStorage.getItem("assessment") == "viva") {
      this.getSystemInfo("VivaMcqAssessment");
    }
    response_object = JSON.parse(
      localStorage.getItem(this.Req + "_" + this.Id + "_data")
    );
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  getSystemInfo(assementName: any) {
    var browser = get_browser();

    let lat = localStorage.getItem("lat");
    let long = localStorage.getItem("long");

    let data: any = JSON.parse(localStorage.getItem("Response_data"));
    console.log("data", data);

    data.CandidateAssessmentData[assementName].AssessmentStatusId =
      environment.ASSESSMENT_FEEDBACK_GIVEN;

    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.SystemInfoDateTime = moment().format(
      "DD-MMM-YYYY hh:mm:ss a"
    );
    data.CandidateAssessmentData[assementName].CandidateSystemInfo.BrowserName =
      browser.name;
    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.BrowserVersion = browser.version;

    data.CandidateAssessmentData[assementName].CandidateSystemInfo.RamSize =
      browser.ramSize + "GB";

    data.CandidateAssessmentData[assementName].CandidateSystemInfo.IPv6Address =
      String(this.ip);

    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.OperatingSystem = this.deviceservice.os;
    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.OperatingSystemVersion =
      this.deviceservice.os_version;
    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.TotalPhysicalMemory =  '' //os.totalmem();
    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.AvailablePhysicalMemory = '' //os.freemem();
    data.CandidateAssessmentData[
      assementName
    ].CandidateSystemInfo.SystemLocale = window.navigator.language;
    data.CandidateAssessmentData[assementName].CandidateSystemInfo.Latitude =
      lat;
    data.CandidateAssessmentData[assementName].CandidateSystemInfo.Longitude =
      long;
    localStorage.setItem("Response_data", JSON.stringify(data));
    localStorage.setItem(
      this.Req + "_" + this.Id + "_" + "data",
      JSON.stringify(data)
    );
    this.createOnline$().subscribe(
      (isOnline) => {
        this.clicked_file_upload();
        this.clicked();
      },
      (errr) => {
        alert(
          "You are offline. Please connect to internet to continue with submission process"
        );
      }
    );
  }

  async onInitFs(fs?: any) {
    if (localStorage.getItem("assessment") == "theory") {
      total_count = 0;
      count = 0;

      if (
        response_object.CandidateAssessmentData.TheoryAssessment
          .ScreenshotImages.length == 0 &&
        response_object.CandidateAssessmentData.TheoryAssessment.SnapshotImages
          .length == 0 &&
        response_object.CandidateAssessmentData.TheoryAssessment
          .CandidVideoFiles.length == 0
      ) {
        $("#loads").css("display", "none");
        $("#progresses").css("display", "none");
        $("#dones").css("display", "block");
      } else {
        if (
          response_object.CandidateAssessmentData.TheoryAssessment
            .ScreenshotImages.length
        ) {
          $.each(
            response_object.CandidateAssessmentData.TheoryAssessment
              .ScreenshotImages,
            function (index, value) {
              total_count += 1;

              ReadFileFromFileSystem(fs, value.Filename);
            }
          );
        }

        if (
          !sessionStorage.getItem("NoWebCamFound") &&
          response_object.CandidateAssessmentData.TheoryAssessment
            .WebcamMandatory &&
          response_object.CandidateAssessmentData.TheoryAssessment
            .SnapshotImages.length
        ) {
          $.each(
            response_object.CandidateAssessmentData.TheoryAssessment
              .SnapshotImages,
            function (index, value) {
              total_count += 1;

              ReadFileFromFileSystem(fs, value.Filename);
            }
          );
        }

        if (
          !sessionStorage.getItem("NoWebCamFound") &&
          response_object.CandidateAssessmentData.TheoryAssessment
            .WebcamMandatory &&
          response_object.CandidateAssessmentData.TheoryAssessment
            .CandidVideoFiles.length
        ) {
          $.each(
            response_object.CandidateAssessmentData.TheoryAssessment
              .CandidVideoFiles,
            function (index, value1) {
              if (value1.FileName != "") {
                total_count += 1;
                ReadVideoFromFileSystem(fs, value1.FileName);
              }
            }
          );
        }
      }
    } else if (localStorage.getItem("assessment") == "practical") {
      total_count = 0;
      var flag = 0;
      if (
        response_object.CandidateAssessmentData.PracticalAssessment
          .ScreenshotImages.length == 0 &&
        response_object.CandidateAssessmentData.PracticalAssessment
          .SnapshotImages.length == 0
      ) {
        flag = 1;
      } else {
        $.each(
          response_object.CandidateAssessmentData.PracticalAssessment
            .ScreenshotImages,
          function (index, value) {
            total_count += 1;
            ReadFileFromFileSystem(fs, value.Filename);
          }
        );
        if (
          !sessionStorage.getItem("NoWebCamFound") &&
          response_object.CandidateAssessmentData.PracticalAssessment
            .WebcamMandatory
        ) {
          $.each(
            response_object.CandidateAssessmentData.PracticalAssessment
              .SnapshotImages,
            function (index, value) {
              total_count += 1;
              ReadFileFromFileSystem(fs, value.Filename);
            }
          );
        }
      }
      if (
        !sessionStorage.getItem("NoWebCamFound") &&
        response_object.CandidateAssessmentData.PracticalAssessment
          .WebcamMandatory
      ) {
        $.each(
          response_object.CandidateAssessmentData.PracticalAssessment.Sections,
          function (index, value1) {
            $.each(
              response_object.CandidateAssessmentData.PracticalAssessment
                .Sections[index].Questions,
              function (ind, value2) {
                if (value2.CandidateResponseVideoFileName != "") {
                  total_count += 1;
                  ReadVideoFromFileSystem(
                    fs,
                    value2.CandidateResponseVideoFileName
                  );
                }
              }
            );
          }
        );
      }
      if (flag == 1 && total_count == 0) {
        $("#loads").css("display", "none");
        $("#progresses").css("display", "none");
        $("#dones").css("display", "block");
      }
    } else if (localStorage.getItem("assessment") == "viva") {
      total_count = 0;

      if (
        response_object.CandidateAssessmentData.VivaMcqAssessment
          .ScreenshotImages.length == 0 &&
        response_object.CandidateAssessmentData.VivaMcqAssessment.SnapshotImages
          .length == 0 &&
        response_object.CandidateAssessmentData.VivaMcqAssessment
          .CandidVideoFiles.length == 0
      ) {
        $("#loads").css("display", "none");
        $("#progresses").css("display", "none");
        $("#dones").css("display", "block");
      } else {
        if (
          response_object.CandidateAssessmentData.VivaMcqAssessment
            .ScreenshotImages.length
        ) {
          $.each(
            response_object.CandidateAssessmentData.VivaMcqAssessment
              .ScreenshotImages,
            function (index, value) {
              total_count += 1;
              ReadFileFromFileSystem(fs, value.Filename);
            }
          );
        }

        if (
          response_object.CandidateAssessmentData.VivaMcqAssessment
            .SnapshotImages.length &&
          !sessionStorage.getItem("NoWebCamFound") &&
          response_object.CandidateAssessmentData.VivaMcqAssessment
            .WebcamMandatory
        ) {
          $.each(
            response_object.CandidateAssessmentData.VivaMcqAssessment
              .SnapshotImages,
            function (index, value) {
              total_count += 1;
              ReadFileFromFileSystem(fs, value.Filename);
            }
          );
        }

        if (
          !sessionStorage.getItem("NoWebCamFound") &&
          response_object.CandidateAssessmentData.VivaMcqAssessment
            .WebcamMandatory &&
          response_object.CandidateAssessmentData.VivaMcqAssessment
            .CandidVideoFiles.length
        ) {
          $.each(
            response_object.CandidateAssessmentData.VivaMcqAssessment
              .CandidVideoFiles,
            function (index, value1) {
              if (value1.FileName != "") {
                total_count += 1;
                ReadVideoFromFileSystem(fs, value1.FileName);
              }
            }
          );
        }
      }
    }
  }

  ngOnDestroy(): void {
    console.log("destroy called");
    count = 0;
  }

  clicked_file_upload() {
    $("#div").css("display", "none");
    $("#submits").css("display", "none");
    $("#loads").css("display", "block");
    $("#progresses").css("display", "block");

    if (this.isFirefox === "Firefox") {
      $("#loads").css("display", "none");
      $("#progresses").css("display", "none");
      $("#dones").css("display", "block");
    } else {
      window.requestFileSystem =
        window.requestFileSystem || window.webkitRequestFileSystem;
      window.requestFileSystem(
        window.TEMPORARY,
        100 * 1024 * 1024,
        this.onInitFs,
        errorHandler
      );
    }
  }

  clicked() {
    $("#div").css("display", "none");
    $("#submit").css("display", "none");
    $("#load").css("display", "block");
    $("#progress").css("display", "block");

    // var response_string = JSON.stringify(
    //   response_object.CandidateAssessmentData
    // );

    var obj = JSON.parse(localStorage.getItem("Response_data"));

    let response_string: any = JSON.stringify(obj.CandidateAssessmentData);
    var date = moment().format("YYYYMMDDhhmmss");

    var filename =
      this.Req + "_" + this.Id + "_" + date + "_response_data.json";

    /*$('#frmImages').append(
      '<input name="response_data" value="' + response + '">'
    );*/

    //var varForm = <HTMLFormElement>document.getElementById('frmImages');

    var varFormdata = new FormData();
    varFormdata.append("response_data", response_string);
    varFormdata.append("response_file_name", filename);
    var data_updated = this.Req + "_" + this.Id + "_data";
    let lat = localStorage.getItem("lat") as string;
    let long = localStorage.getItem("long") as string;
    var response_str = JSON.parse(localStorage.getItem("Response_data"));

    $.ajax({
      url: environment.Upload_files_URL,
      type: "POST",
      data: varFormdata,
      contentType: false,
      cache: false,
      processData: false,
      success: function (response) {
        $.ajax({
          url: environment.Submit_Responsedata_URL,
          type: "POST",
          dataType: "json",
          data: {
            ApiKey: environment.api_key,
            CandidateResponseDataFile: filename,
            DataCompressed: false,
          },
          success: function (reply) {
            $("#div").css("display", "none");
            $("#warning").css("display", "none");
            var reply = JSON.parse(JSON.stringify(reply));

            if (reply.SubmitCandidateAssessmentData.Message == "Success") {
              document.getElementById("warning").innerHTML =
                reply.SubmitCandidateAssessmentData.Message;

              $("#load").css("display", "none");
              $("#progress").css("display", "none");
              $("#done").css("display", "block");

              if (localStorage.getItem("assessment") == "theory") {
                if (
                  parseInt(
                    reply.SubmitCandidateAssessmentData.TheoryResponse
                      .TestSubmissionId
                  ) > 0
                ) {
                  $("#load").css("display", "none");
                  $("#progress").css("display", "none");
                  $("#done").css("display", "block");

                  response_str.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId =
                    environment.ASSESSMENT_SUBMITTED;
                  response_str.CandidateAssessmentData.TheoryAssessment.AssessmentEvents.push(
                    {
                      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                      SubTypeId: 7,
                      Latitude: lat as string,
                      Longitude: long as string,
                    }
                  );
                  localStorage.setItem(
                    data_updated,
                    JSON.stringify(response_str)
                  );
                  localStorage.setItem(
                    this.Req + "_" + this.Id + "_" + "data",
                    JSON.stringify(response_str)
                  );

                  if (
                    response_str.CandidateAssessmentData.PracticalAssessment
                  ) {
                    if (
                      parseInt(
                        response_str.CandidateAssessmentData.PracticalAssessment
                          .AssessmentStatusId
                      ) != environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("btn").style.display = "block";
                    } else if (
                      parseInt(
                        response_str.CandidateAssessmentData.PracticalAssessment
                          .AssessmentStatusId
                      ) == environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("success").style.display =
                        "block";
                    }
                  } else if (
                    response_str.CandidateAssessmentData.VivaMcqAssessment
                  ) {
                    if (
                      parseInt(
                        response_str.CandidateAssessmentData.VivaMcqAssessment
                          .AssessmentStatusId
                      ) != environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("btn").style.display = "block";
                    } else if (
                      parseInt(
                        response_str.CandidateAssessmentData.VivaMcqAssessment
                          .AssessmentStatusId
                      ) == environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("success").style.display =
                        "block";
                    }
                  } else {
                    document.getElementById("success").style.display = "block";
                  }
                }
              } else if (localStorage.getItem("assessment") == "practical") {
                if (
                  parseInt(
                    reply.SubmitCandidateAssessmentData.PracticalResponse
                      .TestSubmissionId
                  ) > 0
                ) {
                  $("#load").css("display", "none");
                  $("#progress").css("display", "none");
                  $("#done").css("display", "block");
                  response_str.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId =
                    environment.ASSESSMENT_SUBMITTED;
                  response_str.CandidateAssessmentData.PracticalAssessment.AssessmentEvents.push(
                    {
                      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                      SubTypeId: 7,
                      Latitude: lat as string,
                      Longitude: long as string,
                    }
                  );
                  localStorage.setItem(
                    data_updated,
                    JSON.stringify(response_str)
                  );

                  localStorage.setItem(
                    this.Req + "_" + this.Id + "_" + "data",
                    JSON.stringify(response_str)
                  );

                  if (response_str.CandidateAssessmentData.TheoryAssessment) {
                    if (
                      parseInt(
                        response_str.CandidateAssessmentData.TheoryAssessment
                          .AssessmentStatusId
                      ) != environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("btn").style.display = "block";
                    } else if (
                      parseInt(
                        response_str.CandidateAssessmentData.TheoryAssessment
                          .AssessmentStatusId
                      ) == environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("success").style.display =
                        "block";
                    }
                  } else {
                    document.getElementById("success").style.display = "block";
                  }
                }
              } else if (localStorage.getItem("assessment") == "viva") {
                if (
                  parseInt(
                    reply.SubmitCandidateAssessmentData.VivaMcqResponse
                      .TestSubmissionId
                  ) > 0
                ) {
                  $("#load").css("display", "none");
                  $("#progress").css("display", "none");
                  $("#done").css("display", "block");
                  response_str.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId =
                    environment.ASSESSMENT_SUBMITTED;
                  response_str.CandidateAssessmentData.VivaMcqAssessment.AssessmentEvents.push(
                    {
                      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                      SubTypeId: 7,
                      Latitude: lat,
                      Longitude: long,
                    }
                  );
                  localStorage.setItem(
                    data_updated,
                    JSON.stringify(response_str)
                  );
                  localStorage.setItem(
                    this.Req + "_" + this.Id + "_" + "data",
                    JSON.stringify(response_str)
                  );

                  if (response_str.CandidateAssessmentData.TheoryAssessment) {
                    if (
                      parseInt(
                        response_str.CandidateAssessmentData.TheoryAssessment
                          .AssessmentStatusId
                      ) != environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("btn").style.display = "block";
                    } else if (
                      parseInt(
                        response_str.CandidateAssessmentData.TheoryAssessment
                          .AssessmentStatusId
                      ) == environment.ASSESSMENT_SUBMITTED
                    ) {
                      document.getElementById("success").style.display =
                        "block";
                    }
                  } else {
                    document.getElementById("success").style.display = "block";
                  }
                }
              }
            }
            localStorage.setItem("Response_data", JSON.stringify(response_str));
            localStorage.setItem(
              this.Req + "_" + this.Id + "_" + "data",
              JSON.stringify(response_str)
            );
          },
          error: function (e) {
            $("#load").css("display", "none");
            $("#progress").css("display", "none");
            $("#submit").css("display", "block");
            $("#div").css("display", "block");
            document.getElementById("warning").innerHTML =
              "Response Data not uploaded. Please try again!";
            if (localStorage.getItem("assessment") == "theory") {
              response_str.CandidateAssessmentData.TheoryAssessment.AssessmentEvents.push(
                {
                  DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                  SubTypeId: 6,
                  Latitude: lat,
                  Longitude: long,
                }
              );
            } else if (localStorage.getItem("assessment") == "practical") {
              response_str.CandidateAssessmentData.PracticalAssessment.AssessmentEvents.push(
                {
                  DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                  SubTypeId: 6,
                  Latitude: lat,
                  Longitude: long,
                }
              );
            } else if (localStorage.getItem("assessment") == "viva") {
              response_str.CandidateAssessmentData.VivaMcqAssessment.AssessmentEvents.push(
                {
                  DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
                  SubTypeId: 6,
                  Latitude: lat,
                  Longitude: long,
                }
              );
            }
            localStorage.setItem(data_updated, JSON.stringify(response_str));
            localStorage.setItem("Response_data", JSON.stringify(response_str));
            localStorage.setItem(
              this.Req + "_" + this.Id + "_" + "data",
              JSON.stringify(response_str)
            );
          },
        });
      },
      error: function (e) {
        $("#load").css("display", "none");
        $("#progress").css("display", "none");
        $("#submit").css("display", "block");
        $("#div").css("display", "block");
        document.getElementById("warning").innerHTML =
          "Response Data not uploaded. Please try again!";
        if (localStorage.getItem("assessment") == "theory") {
          response_str.CandidateAssessmentData.TheoryAssessment.AssessmentEvents.push(
            {
              DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
              SubTypeId: 6,
              Latitude: lat,
              Longitude: long,
            }
          );
        } else if (localStorage.getItem("assessment") == "practical") {
          response_str.CandidateAssessmentData.PracticalAssessment.AssessmentEvents.push(
            {
              DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
              SubTypeId: 6,
              Latitude: lat,
              Longitude: long,
            }
          );
        } else if (localStorage.getItem("assessment") == "viva") {
          response_str.CandidateAssessmentData.VivaMcqAssessment.AssessmentEvents.push(
            {
              DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
              SubTypeId: 6,
              Latitude: lat,
              Longitude: long,
            }
          );
        }
        localStorage.setItem(data_updated, JSON.stringify(response_str));
        localStorage.setItem("Response_data", JSON.stringify(response_str));
        localStorage.setItem(
          this.Req + "_" + this.Id + "_" + "data",
          JSON.stringify(response_str)
        );
      },
    });
  }

  assessment() {
    this.route.navigate(["assessment-details"]);
  }
  finished() {
    let req = localStorage.getItem("req_id");
    let cand = localStorage.getItem("cand_id");
    let UserData = req + "_" + cand + "_data";
    let LoginData = req + "_" + cand;
    let assement = "assessment";
    localStorage.removeItem(UserData);
    localStorage.removeItem(LoginData);
    localStorage.removeItem(assement);

    this.synDatewithServerService.clearAsyc();

    this.route.navigate(["login"]);
  }
}

function ReadFileFromFileSystem(varFs: any, fileName: any) {
  let firefox = myBrowser();

  if (firefox === "Firefox") {
    return false;
  } else {
    varFs.root.getFile(
      fileName,
      {},
      function (fileEntry: any) {
        // Get a File object representing the file,
        // then use FileReader to read its contents.
        fileEntry.file(function (file: any) {
          var reader = new FileReader();
          reader.onloadend = function (e: any) {
            var varFormData = new FormData();

            varFormData.append("image_file_name", fileName);
            varFormData.append("image_data", this.result as string);
            const res = Uploadfiles(varFormData);
          };
          reader.readAsText(file);
        }, errorHandler);
      },
      errorHandler
    );
  }
}

function ReadVideoFromFileSystem(varFs: any, fileName: any) {
  var firefox = myBrowser();
  if (firefox === "Firefox") {
    return false;
  } else {
    varFs.root.getFile(
      fileName,
      {},
      function (fileEntry: any) {
        // Get a File object representing the file,
        // then use FileReader to read its contents.

        fileEntry.file(function (file: any) {
          var reader = new FileReader();

          reader.onloadend = function (e: any) {
            var videovarFormData = new FormData();

            videovarFormData.append("video_file_name", fileName);

            videovarFormData.append("video_data", this.result as string);

            videovarFormData.getAll("video_file_name"); // ["true", "74", "John"]

            const res = Uploadfiles(videovarFormData);
          };
          reader.readAsText(file);
        }, errorHandler);
      },
      errorHandler
    );
  }
}

function Uploadfiles(varFormData: any) {
  var res;
  $.ajax({
    url: environment.Upload_files_URL,
    type: "POST",
    data: varFormData,
    contentType: false,
    cache: false,
    processData: false,
    async: false,
    success: function (response) {
      res = response;
      count += 1;

      if (count == total_count) {
        Event_log("ASSESSMENT_DATA_UPLOADED", response_object);
        $("#loads").css("display", "none");
        $("#progresses").css("display", "none");
        $("#dones").css("display", "block");
      }
    },
    error: function (e) {
      Event_log("ASSESSMENT_DATA_UPLOAD_FAILED", response_object);
    },
  });
  return res;
}

function errorHandler(err: any) {
  console.log(err);

  $("#loads").css("display", "none");
  $("#progresses").css("display", "none");
  $("#dones").css("display", "block");
}

function Event_log(events: string, data: any) {
  let lat = localStorage.getItem("lat");
  let long = localStorage.getItem("long");
  if (localStorage.getItem("assessment") == "theory") {
    var Assessment_event = {
      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
      SubTypeId: 0,
      Description: "",
      Latitude: lat,
      Longitude: long,
    };
    switch (events) {
      case "ASSESSMENT_STARTED":
        Assessment_event.SubTypeId = 1;
        break;
      case "ASSESSMENT_CONTINUED":
        Assessment_event.SubTypeId = 2;
        break;
      case "ASSESSMENT_FINISHED":
        Assessment_event.SubTypeId = 3;
        break;
      case "ASSESSMENT_DATA_UPLOAD_FAILED":
        Assessment_event.SubTypeId = 4;
        Assessment_event.Description = "Assessment data upload failed";
        break;
      case "ASSESSMENT_DATA_UPLOADED":
        Assessment_event.SubTypeId = 5;
        Assessment_event.Description = "Assessment data uploaded";
        break;
      case "ASSESSMENT_SUBMITTED":
        Assessment_event.SubTypeId = 7;
        break;
      case "QUESTION_LINK_CLICKED":
        Assessment_event.SubTypeId = 12;
        break;
      case "PREVIOUS_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 13;
        break;
      case "NEXT_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 14;
        break;
      case "QUESTION_LANGUAGE_CHANGED":
        Assessment_event.SubTypeId = 15;
        break;
      case "QUESTION_MARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 17;
        break;
      case "QUESTION_UNMARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 18;
        break;
      case "OPTION_SELECTED":
        Assessment_event.SubTypeId = 21;
        break;
      case "KEYBOARD_KEY_PRESSED":
        Assessment_event.SubTypeId = 23;
        break;
      case "EXIT_FULLSCREEN":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description =
          "Candidate attempted to exit full screen";
        break;
      case "TAB_SWITCH":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description = "Candidate attempted to switch tabs";
        break;
    }
    data.CandidateAssessmentData.TheoryAssessment.AssessmentEvents.push(
      Assessment_event
    );
    var file =
      localStorage.getItem("req_id") +
      "_" +
      localStorage.getItem("cand_id") +
      "_" +
      "data";
    if (typeof data == "string")
      localStorage.setItem(file, JSON.stringify(data));
    else localStorage.setItem(file, JSON.stringify(data));
  } else if (localStorage.getItem("assessment") == "practical") {
    var Assessment_event = {
      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
      SubTypeId: 0,
      Description: "",
      Latitude: lat,
      Longitude: long,
    };
    switch (events) {
      case "ASSESSMENT_STARTED":
        Assessment_event.SubTypeId = 1;
        break;
      case "ASSESSMENT_CONTINUED":
        Assessment_event.SubTypeId = 2;
        break;
      case "ASSESSMENT_FINISHED":
        Assessment_event.SubTypeId = 3;
        break;
      case "ASSESSMENT_DATA_UPLOAD_FAILED":
        Assessment_event.SubTypeId = 4;
        Assessment_event.Description = "Assessment data upload failed";
        break;
      case "ASSESSMENT_DATA_UPLOADED":
        Assessment_event.SubTypeId = 5;
        Assessment_event.Description = "Assessment data uploaded";
        break;
      case "ASSESSMENT_SUBMITTED":
        Assessment_event.SubTypeId = 7;
        break;
      case "QUESTION_LINK_CLICKED":
        Assessment_event.SubTypeId = 12;
        break;
      case "PREVIOUS_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 13;
        break;
      case "NEXT_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 14;
        break;
      case "QUESTION_LANGUAGE_CHANGED":
        Assessment_event.SubTypeId = 15;
        break;
      case "QUESTION_MARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 17;
        break;
      case "QUESTION_UNMARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 18;
        break;
      case "OPTION_SELECTED":
        Assessment_event.SubTypeId = 21;
        break;
      case "KEYBOARD_KEY_PRESSED":
        Assessment_event.SubTypeId = 23;
        break;
      case "EXIT_FULLSCREEN":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description =
          "Candidate attempted to exit full screen";
        break;
      case "TAB_SWITCH":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description = "Candidate attempted to switch tabs";
        break;
    }
    data.CandidateAssessmentData.PracticalAssessment.AssessmentEvents.push(
      Assessment_event
    );
    var file =
      localStorage.getItem("req_id") +
      "_" +
      localStorage.getItem("cand_id") +
      "_" +
      "data";
    if (typeof data == "string")
      localStorage.setItem(file, JSON.stringify(data));
    else localStorage.setItem(file, JSON.stringify(data));
  } else if (localStorage.getItem("assessment") == "viva") {
    var Assessment_event = {
      DateTime: moment().format("DD-MMM-YYYY h:mm:ss a"),
      SubTypeId: 0,
      Description: "",
      Latitude: lat,
      Longitude: long,
    };
    switch (events) {
      case "ASSESSMENT_STARTED":
        Assessment_event.SubTypeId = 1;
        break;
      case "ASSESSMENT_CONTINUED":
        Assessment_event.SubTypeId = 2;
        break;
      case "ASSESSMENT_FINISHED":
        Assessment_event.SubTypeId = 3;
        break;
      case "ASSESSMENT_DATA_UPLOAD_FAILED":
        Assessment_event.SubTypeId = 4;
        Assessment_event.Description = "Assessment data upload failed";
        break;
      case "ASSESSMENT_DATA_UPLOADED":
        Assessment_event.SubTypeId = 5;
        Assessment_event.Description = "Assessment data uploaded";
        break;
      case "ASSESSMENT_SUBMITTED":
        Assessment_event.SubTypeId = 7;
        break;
      case "QUESTION_LINK_CLICKED":
        Assessment_event.SubTypeId = 12;
        break;
      case "PREVIOUS_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 13;
        break;
      case "NEXT_BUTTON_CLICKED":
        Assessment_event.SubTypeId = 14;
        break;
      case "QUESTION_LANGUAGE_CHANGED":
        Assessment_event.SubTypeId = 15;
        break;
      case "QUESTION_MARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 17;
        break;
      case "QUESTION_UNMARKED_FOR_REVIEW":
        Assessment_event.SubTypeId = 18;
        break;
      case "OPTION_SELECTED":
        Assessment_event.SubTypeId = 21;
        break;
      case "KEYBOARD_KEY_PRESSED":
        Assessment_event.SubTypeId = 23;
        break;
      case "EXIT_FULLSCREEN":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description =
          "Candidate attempted to exit full screen";
        break;
      case "TAB_SWITCH":
        Assessment_event.SubTypeId = 25;
        Assessment_event.Description = "Candidate attempted to switch tabs";
        break;
    }
    data.CandidateAssessmentData.VivaMcqAssessment.AssessmentEvents.push(
      Assessment_event
    );
    var file =
      localStorage.getItem("req_id") +
      "_" +
      localStorage.getItem("cand_id") +
      "_" +
      "data";
    if (typeof data == "string")
      localStorage.setItem(file, JSON.stringify(data));
    else localStorage.setItem(file, JSON.stringify(data));
  }
}

function myBrowser() {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "IE";
  } else {
    return "unknown";
  }
}

function get_browser() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1],
    ramSize: window.navigator?.deviceMemory,
  };
}
