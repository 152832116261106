<footer class="page-footer fixed-bottom font-small green" style="position:fixed">


  <!--     
	<img
    class="center animated flip"
    style="position: absolute;width: 79px;width: 79px;
    top: -23%"
    src="/assets/images/Certiplate_Logo.svg"
  /> -->
  <div class="footer-copyright text-center py-3 animated fadeInDown">© 2012-2022,Copyright. All rights reserved: Powered
    by Certiplate.


    <!-- <a href="https://navriti.com/" target="_blank"><b> Navriti Technologies Pvt Ltd. </b></a> -->
  </div>
</footer>