<div class="modal-footer mx-5 pt-3 mb-1 justify-content-center">




	<p>

		<a style="color: black" href="https://navriti.com/"><b>© 2012-2022,Copyright. All rights reserved: Powered by
				Certiplate.</b></a>
	</p>
</div>