import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SynDatewithServerService } from "../service/syn-datewith-server.service";

declare var $: any;
var req: any;
var cand: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  username: HTMLInputElement;
  password: HTMLInputElement;
  serverDate: any;

  constructor(
    private route: Router,
    private synDatewithServerService: SynDatewithServerService
  ) {}
  async ngOnInit() {
    await this.getCurrentTime();
  }

  showMessgeCompletedAssement() {
    document.getElementById("warning").style.backgroundColor = "lawngreen";
    document.getElementById("warning").innerHTML =
      "<b> <h2>" + "Already, You have submitted the assessment" + "</h2></b>";
    $("#login").css("display", "block");
    $("#log-in").css("display", "none");
  }

  showMessage() {
    document.getElementById("warning").style.backgroundColor = "lawngreen";
    document.getElementById("warning").innerHTML =
      "<b> <h2>" + "You have completed the assessment" + "</h2></b>";
    $("#login").css("display", "block");
    $("#log-in").css("display", "none");
  }

  getCurrentTime() {
    $.ajax({
      url: environment.URL_currentTime,
      type: "GET",
      dataType: "json",
      success: (data: any) => {
        var splitarray = new Array();
        splitarray = data.CurrentServerTimeData.CurrentServerTime.split(" ");
        this.serverDate = splitarray[0];
      },
      error: function (err) {},
    });
  }

  test() {
    $("#login").css("display", "none");
    $("#log-in").css("display", "block");
    this.username = document.getElementById("username") as HTMLInputElement;
    this.password = document.getElementById("pwd") as HTMLInputElement;
    if (this.username.value == "" || this.password.value == "") {
      document.getElementById("warning").innerHTML =
        "<b> <h2>" + "Login Id and password field are required" + "</h2></b>";
      $("#login").css("display", "block");
      $("#log-in").css("display", "none");
    } else if (this.username && this.password) {
      localStorage.setItem(this.username.value, this.password.value);
      if (/^\d+$/.test(this.username.value) == true) {
        let element = document.documentElement;
        if (element.requestFullscreen) element.requestFullscreen();
        this.reLogin();
      } else if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          this.username.value.trim()
        )
      ) {
        this.user_login();
      } else {
        document.getElementById("warning").innerHTML =
          "<b> <h2>" + "Please Enter Valid Input" + "</h2></b>";
        $("#login").css("display", "block");
        $("#log-in").css("display", "none");
      }
    }
  }

  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  clientLogo(data: any) {
    let client = {
      ClientLogoUrl: data.CandidateAssessmentData.ClientLogoUrl,
      ClientName: data.CandidateAssessmentData.ClientName,
    };
    localStorage.setItem("clieInfo", JSON.stringify(client));
  }

  pendingAssesment(data: any) {
    let pendingAssessmentCount = 0;
    if (data.CandidateAssessmentData.TheoryAssessment) {
      if (
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED
      )
        pendingAssessmentCount++;
    }
    if (data.CandidateAssessmentData.PracticalAssessment)
      if (
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED
      )
        pendingAssessmentCount++;
    if (data.CandidateAssessmentData.VivaMcqAssessment)
      if (
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED
      )
        pendingAssessmentCount++;
    if (pendingAssessmentCount < 1) {
      this.showMessgeCompletedAssement();
      return false;
    }
  }

  redirectUser(data: any) {
    localStorage.setItem(
      this.username.value + "_" + this.password.value + "_" + "data",
      JSON.stringify(data)
    );
    this.clientLogo(data);
    let isPending = this.pendingAssesment(data);

    console.log(isPending, "isPending");

    if (isPending === false) {
      return false;
    }

    if (
      data.CandidateAssessmentData.TheoryAssessment &&
      data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED &&
      data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_NOT_STARTED
    ) {
      localStorage.setItem("assessment", "theory");
      localStorage.setItem("Response_data", JSON.stringify(data));

      if (
        (data.CandidateAssessmentData.TheoryAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.TheoryAssessment.StartImage.FileName ==
            "") ||
        (data.CandidateAssessmentData.TheoryAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.TheoryAssessment.IdentityImage
            .FileName == "")
      ) {
        this.route.navigate(["image-capture"]);
      } else if (
        data.CandidateAssessmentData.TheoryAssessment.StartImage.FileName !=
          "" &&
        data.CandidateAssessmentData.TheoryAssessment.IdentityImage.FileName !=
          "" &&
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_NOT_STARTED
      ) {
        this.route.navigate(["theory-instructions"]);
      } else if (
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS
      ) {
        $("#mymodal").modal("show");
      } else if (
        data.CandidateAssessmentData.TheoryAssessment.WebcamMandatory &&
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED &&
        data.CandidateAssessmentData.TheoryAssessment.EndImage.FileName == ""
      )
        this.route.navigate(["end-image-capture"]);
      else if (
        data.CandidateAssessmentData.TheoryAssessment.EndImage.FileName != "" &&
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED
      ) {
        if (
          !data.CandidateAssessmentData.TheoryAssessment
            .DisableCandidateFeedback
        )
          this.route.navigate(["feedback-theory"]);
        else this.route.navigate(["submit-response"]);
      } else if (
        data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_FEEDBACK_GIVEN
      )
        this.route.navigate(["submit-response"]);

      if (data.CandidateAssessmentData.PracticalAssessment) {
        if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId !=
            environment.ASSESSMENT_SUBMITTED
        )
          this.route.navigate(["assessment-details"]);
        else if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      } else if (data.CandidateAssessmentData.VivaMcqAssessment) {
        if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId !=
            environment.ASSESSMENT_SUBMITTED
        )
          this.route.navigate(["assessment-details"]);
        else if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      } else {
        if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      }
    } else if (
      data.CandidateAssessmentData.VivaMcqAssessment &&
      data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED &&
      data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_NOT_STARTED
    ) {
      localStorage.setItem("assessment", "viva");

      if (
        (data.CandidateAssessmentData.VivaMcqAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.VivaMcqAssessment.StartImage.FileName ==
            "") ||
        (data.CandidateAssessmentData.VivaMcqAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.VivaMcqAssessment.IdentityImage
            .FileName == "")
      )
        this.route.navigate(["image-capture"]);
      else if (
        data.CandidateAssessmentData.VivaMcqAssessment.StartImage.FileName !=
          "" &&
        data.CandidateAssessmentData.VivaMcqAssessment.IdentityImage.FileName !=
          "" &&
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_NOT_STARTED
      ) {
        this.route.navigate(["viva-instructions"]);
      } else if (
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS
      ) {
        $("#mymodal").modal("show");
      } else if (
        data.CandidateAssessmentData.VivaMcqAssessment.WebcamMandatory &&
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED &&
        data.CandidateAssessmentData.VivaMcqAssessment.EndImage.FileName == ""
      )
        this.route.navigate(["end-image-capture"]);
      else if (
        data.CandidateAssessmentData.VivaMcqAssessment.EndImage.FileName !=
          "" &&
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED
      ) {
        if (
          !data.CandidateAssessmentData.VivaMcqAssessment
            .DisableCandidateFeedback
        )
          this.route.navigate(["feedback-viva"]);
        else this.route.navigate(["submit-response"]);
      } else if (
        data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_FEEDBACK_GIVEN
      )
        this.route.navigate(["submit-response"]);
      if (data.CandidateAssessmentData.TheoryAssessment) {
        if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId !=
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        )
          this.route.navigate(["assessment-details"]);
        else if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      } else {
        if (
          data.CandidateAssessmentData.VivaMcqAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      }
    } else if (
      data.CandidateAssessmentData?.PracticalAssessment &&
      data.CandidateAssessmentData?.PracticalAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_SUBMITTED &&
      data.CandidateAssessmentData?.PracticalAssessment.AssessmentStatusId !=
        environment.ASSESSMENT_NOT_STARTED
    ) {
      localStorage.setItem("assessment", "practical");
      localStorage.setItem("Response_data", JSON.stringify(data));

      if (
        (data.CandidateAssessmentData.PracticalAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.PracticalAssessment.StartImage
            .FileName == "") ||
        (data.CandidateAssessmentData.PracticalAssessment.WebcamMandatory &&
          data.CandidateAssessmentData.PracticalAssessment.IdentityImage
            .FileName == "")
      )
        this.route.navigate(["image-capture"]);
      else if (
        data.CandidateAssessmentData.PracticalAssessment.StartImage.FileName !=
          "" &&
        data.CandidateAssessmentData.PracticalAssessment.IdentityImage
          .FileName != "" &&
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_NOT_STARTED
      ) {
        this.route.navigate(["practical-instructions"]);
      } else if (
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS
      ) {
        $("#mymodal").modal("show");
      } else if (
        data.CandidateAssessmentData.PracticalAssessment.WebcamMandatory &&
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED &&
        data.CandidateAssessmentData.PracticalAssessment.EndImage.FileName == ""
      )
        this.route.navigate(["end-image-capture"]);
      else if (
        data.CandidateAssessmentData.PracticalAssessment.EndImage.FileName !=
          "" &&
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_FINISHED
      ) {
        if (
          !data.CandidateAssessmentData.PracticalAssessment
            .DisableCandidateFeedback
        )
          this.route.navigate(["feedback-practical"]);
        else this.route.navigate(["submit-response"]);
      } else if (
        data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
        environment.ASSESSMENT_FEEDBACK_GIVEN
      )
        this.route.navigate(["submit-response"]);
      if (data.CandidateAssessmentData.TheoryAssessment) {
        if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId !=
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        )
          this.route.navigate(["assessment-details"]);
        else if (
          data.CandidateAssessmentData.TheoryAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED &&
          data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
            environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      } else {
        if (
          data.CandidateAssessmentData.PracticalAssessment.AssessmentStatusId ==
          environment.ASSESSMENT_SUBMITTED
        ) {
          this.showMessage();
        }
      }
    } else this.route.navigate(["general-instructions"]);
  }

  exist_UUID: any;

  reLogin() {
    this.exist_UUID = localStorage.getItem(
      "req_id" + this.username.value + "_UUID"
    );

    if (!this.exist_UUID) {
      this.exist_UUID = this.create_UUID();
      localStorage.setItem(
        "req_id" + this.username.value + "_UUID",
        this.exist_UUID
      );
    }
    $.ajax({
      url: environment.URL_authentication,
      type: "POST",
      dataType: "json",
      data: {
        apiKey: environment.api_key,
        RegistrationId: this.username.value,
        password: localStorage.getItem(this.username.value),
        Sender: "PROCTORED_BROWSER",
        AssesmentDeviceId: this.exist_UUID,
        /*ApiKey: "'"+environment.api_key+"'",
        RegistrationId: "'"+this.username.value+"'",
        Password: "'"+localStorage.getItem(this.username.value)+"'"*/
        //Sender: 'PROCTORED_BROWSER",
      },
      success: (data) => {
        var json = JSON.parse(JSON.stringify(data));

        localStorage.setItem(
          "CandidateId",
          json.CandidateAssessmentAuthentication.CandidateId
        );

        sessionStorage.setItem(
          "JwtToken",
          data.CandidateAssessmentAuthentication.JwtToken
        );
        localStorage.setItem(
          this.username.value + "_" + this.password.value,
          JSON.stringify(data)
        );

        if (json.CandidateAssessmentAuthentication.Message === "Success") {
          console.log(this.serverDate, "this.serverDate");

          if (
            this.serverDate ==
            json.CandidateAssessmentAuthentication.ScheduledStartDate
          ) {
            if (
              parseInt(
                json.CandidateAssessmentAuthentication.CandidateAttemptCount
              ) >
              parseInt(
                json.CandidateAssessmentAuthentication.MaximumAttemptCount
              )
            ) {
              document.getElementById("warning").innerHTML =
                "<b> <h2>" + "Exceeded Maximum Attempt Count" + "</h2></b>";
              $("#login").css("display", "block");
              $("#log-in").css("display", "none");
            } else {
              req = this.username.value;
              cand = this.password.value;
              localStorage.setItem("req_id", this.username.value);
              localStorage.setItem("cand_id", this.password.value);

              if (localStorage.getItem(req + "_" + cand + "_data")) {
                this.synDatewithServerService.startAsyc();

                var data = JSON.parse(
                  localStorage.getItem(req + "_" + cand + "_data")
                );

                this.clientLogo(data);
                let isPending = this.pendingAssesment(data);

                console.log("isPending", isPending);

                if (isPending === false) {
                  return false;
                }

                if (
                  localStorage.getItem(req + "_" + cand + "_assessment") ==
                  "theory"
                ) {
                  if (
                    (data.CandidateAssessmentData.TheoryAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.TheoryAssessment.StartImage
                        .FileName == "") ||
                    (data.CandidateAssessmentData.TheoryAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.TheoryAssessment
                        .IdentityImage.FileName == "")
                  ) {
                    this.route.navigate(["image-capture"]);
                  } else if (
                    data.CandidateAssessmentData.TheoryAssessment.StartImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.TheoryAssessment.IdentityImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.TheoryAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_NOT_STARTED
                  ) {
                    this.route.navigate(["theory-instructions"]);
                  } else if (
                    data.CandidateAssessmentData.TheoryAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_IN_PROGRESS
                  ) {
                    $("#mymodal").modal("show");
                  } else if (
                    data.CandidateAssessmentData.TheoryAssessment
                      .WebcamMandatory &&
                    data.CandidateAssessmentData.TheoryAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED &&
                    data.CandidateAssessmentData.TheoryAssessment.EndImage
                      .FileName == ""
                  )
                    this.route.navigate(["end-image-capture"]);
                  else if (
                    data.CandidateAssessmentData.TheoryAssessment.EndImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.TheoryAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED
                  ) {
                    if (
                      !data.CandidateAssessmentData.TheoryAssessment
                        .DisableCandidateFeedback
                    )
                      this.route.navigate(["feedback-theory"]);
                    else this.route.navigate(["submit-response"]);
                  } else if (
                    data.CandidateAssessmentData.TheoryAssessment
                      .AssessmentStatusId ==
                    environment.ASSESSMENT_FEEDBACK_GIVEN
                  )
                    this.route.navigate(["submit-response"]);
                  if (data.CandidateAssessmentData.PracticalAssessment) {
                    if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .AssessmentStatusId != environment.ASSESSMENT_SUBMITTED
                    )
                      this.route.navigate(["assessment-details"]);
                    else if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  } else if (data.CandidateAssessmentData.VivaMcqAssessment) {
                    if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .AssessmentStatusId != environment.ASSESSMENT_SUBMITTED
                    )
                      this.route.navigate(["assessment-details"]);
                    else if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  } else {
                    if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  }
                } else if (
                  localStorage.getItem(req + "_" + cand + "_assessment") ==
                  "practical"
                ) {
                  if (
                    (data.CandidateAssessmentData.PracticalAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .StartImage.FileName == "") ||
                    (data.CandidateAssessmentData.PracticalAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .IdentityImage.FileName == "")
                  )
                    this.route.navigate(["image-capture"]);
                  else if (
                    data.CandidateAssessmentData.PracticalAssessment.StartImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.PracticalAssessment
                      .IdentityImage.FileName != "" &&
                    data.CandidateAssessmentData.PracticalAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_NOT_STARTED
                  ) {
                    this.route.navigate(["practical-instructions"]);
                  } else if (
                    data.CandidateAssessmentData.PracticalAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_IN_PROGRESS
                  ) {
                    $("#mymodal").modal("show");
                  } else if (
                    data.CandidateAssessmentData.PracticalAssessment
                      .WebcamMandatory &&
                    data.CandidateAssessmentData.PracticalAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED &&
                    data.CandidateAssessmentData.PracticalAssessment.EndImage
                      .FileName == ""
                  )
                    this.route.navigate(["end-image-capture"]);
                  else if (
                    data.CandidateAssessmentData.PracticalAssessment.EndImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.PracticalAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED
                  ) {
                    if (
                      !data.CandidateAssessmentData.PracticalAssessment
                        .DisableCandidateFeedback
                    )
                      this.route.navigate(["feedback-practical"]);
                    else this.route.navigate(["submit-response"]);
                  } else if (
                    data.CandidateAssessmentData.PracticalAssessment
                      .AssessmentStatusId ==
                    environment.ASSESSMENT_FEEDBACK_GIVEN
                  )
                    this.route.navigate(["submit-response"]);
                  if (data.CandidateAssessmentData.TheoryAssessment) {
                    if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId !=
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    )
                      this.route.navigate(["assessment-details"]);
                    else if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.PracticalAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  } else {
                    if (
                      data.CandidateAssessmentData.PracticalAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  }
                } else if (
                  localStorage.getItem(req + "_" + cand + "_assessment") ==
                  "viva"
                ) {
                  if (
                    (data.CandidateAssessmentData.VivaMcqAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.VivaMcqAssessment.StartImage
                        .FileName == "") ||
                    (data.CandidateAssessmentData.VivaMcqAssessment
                      .WebcamMandatory &&
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .IdentityImage.FileName == "")
                  )
                    this.route.navigate(["image-capture"]);
                  else if (
                    data.CandidateAssessmentData.VivaMcqAssessment.StartImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.VivaMcqAssessment.IdentityImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_NOT_STARTED
                  ) {
                    this.route.navigate(["viva-instructions"]);
                  } else if (
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_IN_PROGRESS
                  ) {
                    $("#mymodal").modal("show");
                  } else if (
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .WebcamMandatory &&
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED &&
                    data.CandidateAssessmentData.VivaMcqAssessment.EndImage
                      .FileName == ""
                  )
                    this.route.navigate(["end-image-capture"]);
                  else if (
                    data.CandidateAssessmentData.VivaMcqAssessment.EndImage
                      .FileName != "" &&
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .AssessmentStatusId == environment.ASSESSMENT_FINISHED
                  ) {
                    if (
                      !data.CandidateAssessmentData.VivaMcqAssessment
                        .DisableCandidateFeedback
                    )
                      this.route.navigate(["feedback-viva"]);
                    else this.route.navigate(["submit-response"]);
                  } else if (
                    data.CandidateAssessmentData.VivaMcqAssessment
                      .AssessmentStatusId ==
                    environment.ASSESSMENT_FEEDBACK_GIVEN
                  )
                    this.route.navigate(["submit-response"]);
                  if (data.CandidateAssessmentData.TheoryAssessment) {
                    if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId !=
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    )
                      this.route.navigate(["assessment-details"]);
                    else if (
                      data.CandidateAssessmentData.TheoryAssessment
                        .AssessmentStatusId ==
                        environment.ASSESSMENT_SUBMITTED &&
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  } else {
                    if (
                      data.CandidateAssessmentData.VivaMcqAssessment
                        .AssessmentStatusId == environment.ASSESSMENT_SUBMITTED
                    ) {
                      this.showMessage();
                    }
                  }
                } else this.route.navigate(["assessment-details"]);
              } else {
                $.ajax({
                  url: environment.URL_datarequest,
                  type: "POST",
                  dataType: "json",
                  data: {
                    apiKey: environment.api_key,
                    RegistrationId: this.username.value,
                    password: this.password.value,
                  },
                  success: (data) => {
                    var datas = JSON.parse(JSON.stringify(data));

                    localStorage.setItem("req_id", this.username.value);
                    localStorage.setItem("cand_id", this.password.value);
                    localStorage.setItem(
                      this.username.value +
                        "_" +
                        this.password.value +
                        "_" +
                        "data",
                      JSON.stringify(datas)
                    );

                    this.redirectUser(datas);

                    // this.route.navigate(["general-instructions"]);
                  },
                  error: function (err) {},
                });
              }
            }
          } else {
            document.getElementById("warning").innerHTML =
              "<b><h2>" +
              "No assessment has been scheduled for you today! Please contact the system administrator for assistance" +
              "</h2></b>";
            $("#login").css("display", "block");
            $("#log-in").css("display", "none");
          }
        } else {
          console.log(
            "json.CandidateAssessmentAuthentication.Message",
            json.CandidateAssessmentAuthentication.Message
          );

          if (
            json.CandidateAssessmentAuthentication.Message ===
            "Invalid user credentials!"
          ) {
            document.getElementById("warning").innerHTML =
              "<b><h2>" +
              "The Login ID or Password you entered is incorrect." +
              "</h2></b>";
            $("#login").css("display", "block");
            $("#log-in").css("display", "none");
          } else {
            document.getElementById("warning").innerHTML =
              "<b><h2>" +
              json.CandidateAssessmentAuthentication.Message +
              "</h2></b>";
            $("#login").css("display", "block");
            $("#log-in").css("display", "none");
          }
        }
      },
      error: function (err) {
        $("#login").css("display", "block");
        $("#log-in").css("display", "none");
      },
    });
  }

  user_login() {
    this.exist_UUID = localStorage.getItem(
      "req_id" + this.username.value + "_UUID"
    );

    if (!this.exist_UUID) {
      this.exist_UUID = this.create_UUID();
      localStorage.setItem(
        "req_id" + this.username.value + "_UUID",
        this.exist_UUID
      );
    }
    $.ajax({
      url: environment.URL_authentication_email,
      type: "POST",
      dataType: "json",
      data: {
        ApiKey: environment.ApiKey,
        LoginId: this.username.value,
        password: this.password.value,
        ClientIpAddress: environment.ClientIP,
        ClientBrowser: environment.ClientBrowser,
        AssesmentDeviceId: this.exist_UUID,
      },
      success: (data: any) => {
        var json = JSON.parse(JSON.stringify(data));
        localStorage.setItem(
          json.AuthenticationResponseData.UserId,
          JSON.stringify(data)
        );
        localStorage.setItem(
          "CandidateId",
          json.CandidateAssessmentAuthentication.CandidateId
        );

        localStorage.setItem("UserId", json.AuthenticationResponseData.UserId);
        localStorage.setItem(
          "UserRoleId",
          json.AuthenticationResponseData.UserRoleId
        );
        sessionStorage.setItem("req_id", this.username.value);
        sessionStorage.setItem(
          "UserId",
          json.AuthenticationResponseData.UserId
        );
        sessionStorage.setItem("password", this.password.value);
        sessionStorage.setItem(
          "SessionId",
          json.AuthenticationResponseData.SessionId
        );
        sessionStorage.setItem(
          "SessionId",
          json.AuthenticationResponseData.SessionId
        );
        sessionStorage.setItem(
          "UserroleId",
          json.AuthenticationResponseData.UserRoleId
        );
        if (
          json.AuthenticationResponseData.Message ==
          "User authentication success"
        ) {
          if (json.AuthenticationResponseData.UserRoleId == 12) {
            this.route.navigate(["proctor-count-views"]);
          } else if (json.AuthenticationResponseData.UserRoleId == 3) {
            this.route.navigate(["AssessorDetails"]);
          } else if (
            json.AuthenticationResponseData.UserRoleId == 22 ||
            json.AuthenticationResponseData.UserRoleId == 0
          ) {
            this.route.navigate(["home"]);
          }
        } else {
          document.getElementById("warning").innerHTML =
            "<b><h2>" + json.AuthenticationResponseData.Message + "</h2></b>";
          $("#login").css("display", "block");
          $("#log-in").css("display", "none");
        }
      },
      error: function (err: any) {
        $("#login").css("display", "block");
        $("#log-in").css("display", "none");
      },
    });
  }

  clicked() {
    let data = JSON.parse(
      localStorage.getItem(
        this.username.value + "_" + this.password.value + "_data"
      )
    );

    let assementdate = data.CandidateAssessmentData;

    let element = document.documentElement;
    if (element.requestFullscreen) element.requestFullscreen();
    if (
      assementdate?.TheoryAssessment?.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS ||
      localStorage.getItem(req + "_" + cand + "_assessment") == "theory"
    )
      this.route.navigate(["theory-assessment"]);
    else if (
      assementdate?.PracticalAssessment?.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS ||
      localStorage.getItem(req + "_" + cand + "_assessment") == "practical"
    )
      this.route.navigate(["practical-assessment"]);
    else if (
      assementdate?.VivaMcqAssessment?.AssessmentStatusId ==
        environment.ASSESSMENT_IN_PROGRESS ||
      localStorage.getItem(req + "_" + cand + "_assessment") == "viva"
    )
      this.route.navigate(["viva-assessment"]);
  }
}
