<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <!-- MDB icon -->
  <link rel="icon" href="img/mdb-favicon.ico" type="image/x-icon" />
  <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
  <!-- Font Awesome -->
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.11.2/css/all.css" />
  <!-- Google Fonts Roboto -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
  <!-- Font Awesome -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" rel="stylesheet" />
  <!-- Material Design Bootstrap -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css" rel="stylesheet" />

  <link href="http://vjs.zencdn.net/5.9.2/video-js.css" rel="stylesheet">



  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
    integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous" />

</head>

<body>
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark bg-success" [containerInside]="false">
    <!-- <img
        class="animated flip"
        style="position: absolute"
        src="/assets/images/logo.png"
      /> -->


    <img *ngIf="clientInfo?.ClientLogoUrl" style="width: 74px;
      margin-left: 12px;" [src]="clientInfo.ClientLogoUrl" />
    <h2 *ngIf="!clientInfo?.ClientLogoUrl"> <b>{{clientInfo.ClientName}}</b> </h2>

    <i *ngIf="isMobile" class="fad fa-users fa-3x" title="Click here to see candidate information" style="cursor: pointer;
    margin-right: 7px;
    position: fixed;
    right: 0;" (click)="openDialog()"></i>


    <div id="info1" class="info1"></div>
    <div id="info2" class="info2"></div>
    <div id="info3" class="info3"></div>
    <div class="countDown">
      <b>
        <countdown class="countDownmain" [config]="{
              leftTime: LeftTime,
              notify: time_array
            }" (event)="timeup($event)"></countdown>
      </b>
    </div>
  </mdb-navbar>

  <div>
    <div class="questions">
      <h1><b>QUESTION</b></h1>
      <br />
      <b>
        <div id="question"></div>
      </b><br />
      <img src="" style="display: none; height: 150px; width: 150px" id="img" />    
    </div>
    <div style="clear:both;">
      <div class="fileUpload" style="display:flex;flex-flow: row wrap; align-items: baseline;">
        <div (click)="uploadVideo()">
          <label class="image-upload-container btn btn-bwm">
            <span>Upload Video</span>
          </label>
        </div>
        <div (click)="uploadEvidence()">
          <label class="image-upload-container btn btn-bwm">
            <span>Upload Files</span>
          </label>
        </div>
        <div>
          <button (click)="reset()">Reset </button>
        </div> 
        <br>
        <br>
        <br>
      </div>
  
      <div id="fileuploadBox">
        <div *ngIf="showUploadInput" class="chooseFile">
          <label class="image-upload-container btn btn-bwm">
            <input *ngIf="showUploadInput" #imageInput type="file" multiple
              accept="image/*,application/pdf, application/vnd.ms-excel" (change)="processFileNew(imageInput)">
          </label>
          <!-- <span style="color: red;" *ngIf="!isUploaded">Max file size 20 MB.</span> <br> -->
          <span *ngIf="isUploaded && !fileLimitExceed" style="color: green; font-style: italic;
          font-weight: bold;"> File Uploaded SuccessFully</span>
          <span *ngIf="fileLimitExceed" style="color: red;">File size exceeds the maximum limit.</span> 
          <br>
        </div>
      </div>
    </div> 
  </div>

  <div id="myModal" class="modal" style="
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.9);
      ">
    <span class="close">&times;</span>

    <!-- Modal Content (The Image) -->
    <img class="modal-content animated rotateIn" style="margin: auto; display: block; height: auto; width: auto"
      id="img01" />
  </div>

  
  
  <b>
    <span id="fileUpload" style="color: green;font-size: 20px;left: 26%;
    position: absolute;"></span>
    <br>

  </b>


  <div >
    <div [hidden]="isVideoinput" class="video" id="vid" ></div>

  </div>
  





  <form style="display: none" id="frmImages"></form>
  <form style="display: none" id="frmVideos"></form>
  <video style="display: none" id="video" autoplay muted></video>
  <canvas style="display: none" id="canvas" width="640" height="480"></canvas>
  <div class="selectbox">
    <select name="drop" id="dropdown" style="size: 20px">
      <option id="English">English</option>
      <option style="display: none" id="Hindi">Hindi</option>
      <option style="display: none" id="Tamil">Tamil</option>
      <option style="display: none" id="Kannada">Kannada</option>
      <option style="display: none" id="Telugu">Telugu</option>
      <option style="display: none" id="Malayalam">Malayalam</option>
      <option style="display: none" id="Gujarati">Gujarati</option>
      <option style="display: none" id="Marathi">Marathi</option>
      <option style="display: none" id="Bengali">Bengali</option>
      <option style="display: none" id="Punjabi">Punjabi</option>
      <option style="display: none" id="Urdu">Urdu</option>
      <option style="display: none" id="Manipuri">Manipuri</option>
      <option style="display: none" id="Oriya">Oriya</option>
      <option style="display: none" id="Assamese">Assamese</option>
    </select>
  </div>

  <div *ngIf="isMobile" class="sectionLink" (click)="showSectionLink()">
    <a> Click here to {{showSection ? 'hide' : 'show'}} sections list</a>
  </div>
  <div class="card col-md-12 mb-4 text-center section" id="PracticalSec">
    <div class="card-body" id="card">
      <h4 class="card-title"><a>All Sections</a></h4>
    </div>
    <br />
  </div>

  <div class="card subMenubottom" *ngIf="!isMobile" [hidden]="true">
    <div class="card-body" style="padding: 0.25rem;">
      <div class="col-sm-14">
        <table>
          <tr>
            <td>
              <h2 style="font-size: 18px; margin-top: 11px" class="card-title">
                <b>Question Link Legends:</b>
              </h2>
            </td>
            <td>
              <label class="btn btn-danger" style="
                    background-color: #c42737;
                    width: 180px;
                    cursor: default;
                  ">Not Answered</label>
            </td>
            <td>
              <label class="btn btn-success" style="
                    background-attachment: #298d74;
                    width: 180px;
                    cursor: default;
                  ">Answered</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="card bottomNav">
    <div class="card-body" style="padding: 00.25rem;">
      <button id="previous" mdbtn class="btn btn-primary prev" (click)="previous()" disabled>
        Previous
      </button>
      <button id="next" mdbtn class="btn btn-secondary next" (click)="next()">
        Next
      </button>

      <button type="button" id="submit_reponse_btn" class="btn btn-primary finishbtn" (click)="submit()"
        data-toggle="modal" data-target="#fullHeightModalRight">
        Finish Assessment
      </button>
    </div>
  </div>
  <!-- Half Height Modal Right -->
  <div class="modal fade left" id="fullHeightModalRight" style="background: black" tabindex="-1" role="dialog"
    data-backdrop="static" aria-labelledby="myModalLabel" aria-hidden="true">
    <!-- Add class .modal-full-height and then add class .modal-right (or other classes from list above) to set a position to the modal -->
    <div class="modal-dialog modal-half-height modal-left" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title w-100" id="myModalLabel">SUBMISSION</h2>
        </div>
        <div class="modal-body lastAssesment" id="1"></div>
        <div class="modal-footer justify-content-center">
          <h2>Do you really want to finish the assessment?</h2>
          <div>
            <button style="border-radius: 20px" id="yes" type="button" mdbBtn gradient="purple" data-dismiss="modal"
              (click)="yes()">
              Yes
            </button>
            <button style="border-radius: 20px" id="no" type="button" mdbBtn gradient="aqua" data-dismiss="modal">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="centerContainer" style="display: none">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <mat-card class="videoCard mat-elevation-z9">
            <video autoplay muted loop id="myVideo"
              style="position:fixed,bottom:0;right:0;min-width:0%;min-height:0%;display:none"></video>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <!-- Half Height Modal Right -->
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <!-- Bootstrap tooltips -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"></script>
  <!-- Bootstrap core JavaScript -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- MDB core JavaScript -->
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"></script>
</body>
<div class="fullscreen-container">
  <div class="alert" id="popup"
    style="opacity:0;position:absolute;top:23%;left:29%;background:black;border:1px solid #b3b3b3;width:600px;text-align:center;padding 40px;box-shadow:0px 50px 50px rgba(0,0,0,0.6)">
    <br />
    <i class="fas fa-exclamation" style="
          font-size: 60px;
          color: orange;
          border: 5px solid orange;
          padding: 20px 40px;
          border-radius: 50%;
        "></i><br /><br />
    <div style="color: white" id="message"></div>
    <br />
    <button class="btn btn-danger" id="ok">OK</button><br /><br />
  </div>
</div>

</html>