<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <!-- MDB icon -->
  <link rel="icon" href="img/mdb-favicon.ico" type="image/x-icon" />
  <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
  <!-- Font Awesome -->
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.11.2/css/all.css" />
  <!-- Google Fonts Roboto -->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
  <!-- Font Awesome -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" rel="stylesheet" />
  <!-- Material Design Bootstrap -->
  <link href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/css/mdb.min.css" rel="stylesheet" />
  <style>
    .table-hover {
      background-color: white;
    }

    #fade {
      display: none;
      position: fixed;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 1001;
      -moz-opacity: 1;
      opacity: 1;
      filter: alpha(opacity=1.0);
    }

    #light {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 600px;
      max-height: 360px;
      margin-left: -300px;
      margin-top: -180px;
      border: 2px solid #fff;
      background: #fff;
      z-index: 1002;
      overflow: visible;
    }

    #boxclose {
      float: right;
      cursor: pointer;
      color: #fff;
      border: 1px solid #aeaeae;
      border-radius: 3px;
      background: #222222;
      font-size: 31px;
      font-weight: bold;
      display: inline-block;
      line-height: 0px;
      padding: 11px 3px;
      position: absolute;
      right: 2px;
      top: 2px;
      z-index: 1002;
      opacity: 0.9;
    }

    .boxclose:before {
      content: "×";
    }

    #fade:hover~#boxclose {
      display: none;
    }

    .test:hover~.test2 {
      display: none;
    }
  </style>
</head>

<body>
  <app-nav-bar></app-nav-bar>
  <div class="animated heartBeat mainTitle">
    <h1>ASSESSMENT DETAILS</h1>
  </div>
  <div class="mainBlock">
    <table id="tablecontent" class="table table-hover shadow-lg"></table>
  </div>

  <button mdbBtn type="button" id="btn1" class="btn purple-gradient animated rubberBand theorybtn" mdbWavesEffect
    (click)="clicked_theory()">
    Theory</button><br />
  <i id="vid1" (click)="vid_theory()" class="fas fa-play-circle fa-medium theoryVid">
    Tutorial</i>

  <button mdbBtn type="button" id="btn2" class="btn blue-gradient animated rubberBand practicalbtn" mdbWavesEffect
    (click)="clicked_practical()">
    Practical</button><br />
  <i id="vid2" (click)="vid_practical()" class="fas fa-play-circle fa-medium practicalVid">
    Tutorial</i>

  <button mdbBtn type="button" id="btn3" class="btn blue-gradient animated rubberBand vivabtn" mdbWavesEffect
    (click)="clicked_viva()">
    Viva</button><br />
  <i id="vid3" (click)="vid_viva()" class="fas fa-play-circle fa-medium vivaVid">
    Tutorial</i>

  <div id="light">
    <a class="boxclose" id="boxclose" (click)="close()"></a>
    <video style="display: none" id="example1" width="600" controls>
      <source src="" type="video/mp4" />
    </video>
    <video style="display: none" id="example2" width="600" controls>
      <source src="" type="video/mp4" />
    </video>
    <video style="display: none" id="example3" width="600" controls>
      <source src="" type="video/mp4" />
    </video>
  </div>

  <div id="fade" (click)="close()"></div>

  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <!-- Bootstrap tooltips -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"></script>
  <!-- Bootstrap core JavaScript -->
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- MDB core JavaScript -->
  <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.19.1/js/mdb.min.js"></script>
  <script src="https://github.com/muaz-khan/DetectRTC/releases/download/1.4.1/DetectRTC.js"></script>
</body>
<app-footer></app-footer>

</html>